import { REFRESH_TOKEN_STORAGE_NAME, TOKEN_STORAGE_NAME } from "@/constants/global";
import { CommonAuthenticationService } from "@/services/common/authentication.service";
import { CommonCacheService } from "@/services/common/cache.service";
import { getAccount } from "@/services/objects/account/account.service";
import { CacheKeys } from "@/utils/key-cache";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt from "jwt-decode";
import { UserLogin } from "types/user";

export interface UserState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user?: Record<string, any>;
  scope: string;
}

export type SessionInfo = Pick<UserState, "user" | "scope">;

const initialState: UserState = {
  isInitialized: false,
  isAuthenticated: false,
  user: undefined,
  scope: "",
};

export const getSessionInfo = createAsyncThunk("user/getSessionInfo", async (_, thunkApi) => {
  try {
    const accessToken = `${CommonCacheService.Get(CacheKeys.KEY_CACHE_TOKEN)}`;

    console.log('accessToken', accessToken);

    let scope = "";

    if (accessToken) {
      const account = CommonCacheService.Get(CacheKeys.KEY_CACHE_ACCOUNT);
      const sessionInfo = { user: account, scope } as SessionInfo;
      thunkApi.dispatch(updateSession(sessionInfo));
    }
  } catch (err) {
    console.error(err);
  } finally {
    thunkApi.dispatch(initSession()); // turn off loader and init page
  }
});

export const loginAsyncThunk = createAsyncThunk(
  "user/loginAsyncThunk", async ({ email, password }: UserLogin, { rejectWithValue }) => {
    try {
      const result = await CommonAuthenticationService.authenticate({}, { email, password });
      CommonCacheService.Set(CacheKeys.KEY_CACHE_TOKEN, result.data.data, CommonCacheService.CACHE_EXPIRE_NONE);
      const account = await getMyAccount();

      if (account) {
        window.location.href = `${window.location.pathname.replace('login', '')}`;
      }

      return { user: account, scope: '' };
    } catch (err) {
      return rejectWithValue(JSON.stringify(err as any));
    }
  }
);

export const getMyAccount = async () => {
  try {
    const result = await getAccount();

    console.log('result', result);
    if (!result) {
      return;
    }
    CommonCacheService.Set(CacheKeys.KEY_CACHE_ACCOUNT, result.data, CommonCacheService.CACHE_EXPIRE_NONE);

    return result.data;
  } catch (error) {
    console.log(error);

    return;
  }
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initSession: (state) => {
      state.isInitialized = true;
    },
    updateSession: (state, action) => {
      const { payload } = action as PayloadAction<SessionInfo>;
      state.user = payload.user;
      state.isAuthenticated = true;
      state.scope = payload.scope;
    },
    clearSession: (state) => {
      state.user = undefined;
      state.isAuthenticated = false;
      state.scope = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsyncThunk.fulfilled, (state, action /* : PayloadAction<SessionInfo> */) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.scope = action.payload.scope;
    });
    builder.addCase(loginAsyncThunk.rejected, (state, action) => {
      localStorage.removeItem(TOKEN_STORAGE_NAME);
      localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
    });
  },
});

export const { updateSession, clearSession, initSession } = userSlice.actions;

export default userSlice.reducer;
