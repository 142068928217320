/* eslint-disable no-useless-escape */
export class DefinePattern {
  public static get EMAIL() {
    return /^[A-z0-9_\.\+\-]+@([A-z0-9\-]+\.){1}([A-z0-9\-]+[\.]?)+([A-z0-9]+)$/;
  }
  public static get URL() {
    return /^(http|https|ftp):(\/){2}[^\s]+[.]{1}[^\s]+$/;
  }

  public static get HOST_URL() {
    return /^((https|http|ftp):[/]{2}[^/\s]+)/;
  }

  public static get DOMAIN() {
    return /^([a-zA-Z0-9])(([a-z0-9-]{1,61})?[a-z0-9]{1})?(\.[a-z0-9](([a-z0-9-]{1,61})?[a-z0-9]{1})?)?(\.[a-zA-Z]{2,4})+$/;
  }

  public static get MOBILE_PHONE() {
    return /^(\+?84|0|84)([0-9]{9})$/;
  }

  public static get PHONE() {
    return /^(\+?84|[0-9]|84)([0-9]{2,9})$/;
  }

  public static get PHONE_NORMAL() {
    return /^(\+?84|[0-9]|84)([0-9]{9,10})$/;
  }

  public static get NUMBER() {
    return /\d+/g;
  }
  public static get ENCODE_URI() {
    return /%([0-9A-F]{2})/g;
  }
  public static get NAME() {
    return /^\w+[A-Za-z\s\d]+$/;
  }
  public static get NAME_UTF8() {
    return /^[ àáảãạăắằẵặẳâầấậẫẩđèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹÀÁẢÃẠĂẮẰẴẶẲÂẦẤẬẪẨĐÈÉẺẼẸÊỀẾỂỄỆÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸA-Za-z0-9]+$/;
  }
  public static get NAME_SPECIAL() {
    return /[~!@#$%^&*()-+=<>,?\/\\:;"']/;
  }
  public static get NAME_PROFILE() {
    return /([\w\W\d\s]+)+/;
  }
  public static get EMOJI() {
    return /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
  }
  public static get RULE_FIELD_REPLACE() {
    return /[{]{2}[a-zA-Z_-]+[}]{2}/g;
  }
  public static get GET_FIELD_BY_RULE_FIELD_REPLACE() {
    return /[a-zA-Z_-]+/g;
  }

  public static get PEM() {
    return /^(0|1):([0-9]{1,2}):(\{\{path-api\}\}):([a-zA-Z0-9\/]+)$/g;
  }

  public static get TAX() {
    return /^([0-9]){10}(-[0-9]{3})?$/;
  }

}
