import "./card.scss";
import { Text } from "@chakra-ui/react";

interface RefCardPropInterface {
  image?: string,
  title?: string,
  description?: string,
  updatedTime?: string
}

const RefCardComponent = (props: RefCardPropInterface) => {
  const { image, title, description, updatedTime } = props;

  return (
    <div className="ref-card-container">
      <img className="image" src="" />
      <div className="title">{title}</div>
      <Text noOfLines={[1, 2]} className="description">
        "The quick brown fox jumps over the lazy dog" is an English-language pangram—a
        sentence that contains all of the letters of the English alphabet. Owing to
        its existence, Chakra was created.
      </Text>
    </div>
  );
};

export default RefCardComponent;
