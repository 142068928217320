import { Box, Checkbox as ChakraCheckbox, Flex, IconButton } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.25rem;
  border: solid 1px #a0aec0;
  width: 100%;
  border-radius: 0.5rem;
`;

export const Wrapper = styled(Flex)`
  background: #ebf8ff;
  border-radius: 1rem;
  padding: 0.125rem 0.5rem;
  padding-right: 0.125rem;
  font-size: 14px;
  align-items: center;
  gap: 2px;
`;

export const Icon = styled(IconButton)`
  justify-content: center;
  display: flex;
  color: var(--chakra-colors-gray-700);
  &:hover {
    color: var(--chakra-colors-gray-700);
  }
`;

export const Checkbox = styled(ChakraCheckbox)`
  .chakra-checkbox__label {
    font-size: 0.875rem;
  }
`;
