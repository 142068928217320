import { CommonAgentService } from './agent.service';
import { isNil } from 'lodash';
import { DECRYPT, ENCRYPT } from '@/utils/crypto';
import dayjs from 'dayjs';

export class CommonCacheService {
  private static storage: { [key: string]: unknown };
  public static readonly CACHE_EXPIRE_TIME_DEFAULT = 5 * 60;
  public static readonly CACHE_EXPIRE_NONE = -1;

  private static get LocalStorage(): any {
    try {
      if (typeof window.localStorage !== 'undefined') {
        const OS = CommonAgentService.OS();

        if (OS.includes('Safari') && parseFloat(OS.split(' ').pop() || '0') >= 16) {

          return this.getLocalStorageFakeOnSafari();
        }

        return localStorage;
      }

      return this.getLocalStorageFake();
    } catch (error) {
      return this.getLocalStorageFake();
    }
  }

  private static getLocalStorageFakeOnSafari() {
    if (typeof window.localStorage !== 'undefined' && !CommonCacheService.storage && Object.keys(localStorage).length) {
      this.storage = { ...localStorage };
    }

    return {
      setItem: (key: string, value: string) => {
        localStorage.setItem(key, value);
        this.storage[key] = value;
      },
      getItem: (key: string) => {
        const value = localStorage.getItem(key);

        if (value) {
          return value;
        }

        if (!this.storage || isNil(this.storage[key])) {
          return null;
        }

        localStorage.setItem(key, this.storage[key] as string);

        return this.storage?.[key];
      },
      removeItem: (key: string) => {
        delete this.storage?.[key];
        localStorage.removeItem(key);
      },
      clear: () => {
        this.storage = {};
        localStorage.clear();
      }
    };
  }

  private static getLocalStorageFake() {
    if (!CommonCacheService.storage) {
      this.storage = {};
    }

    return {
      setItem: (key: string, value: unknown) => {
        this.storage[key] = value;
      },
      getItem: (key: string) => {
        return this.storage?.[key];
      },
      removeItem: (key: string) => {
        delete this.storage?.[key];
      },
      clear: () => {
        this.storage = {};
      }
    };
  }

  public get(key: string, default_value?: any) {
    return CommonCacheService.Get(key, default_value);
  }

  static Get(key: string, default_value?: any) {
    if (!key) {
      return this.getDefaultValueBySpecificKey(key, default_value);
    }
    const cachedData = this.LocalStorage.getItem(key);

    if (!cachedData) {
      return this.getDefaultValueBySpecificKey(key, default_value);
    }
    try {
      const data = JSON.parse(DECRYPT(cachedData));

      if (data.expire === CommonCacheService.CACHE_EXPIRE_NONE) {
        return data.value;
      }
      const currentMillisecond = dayjs().unix();

      if (data.expire < currentMillisecond) {
        return this.getDefaultValueBySpecificKey(key, default_value);
      }

      return data.value;

    } catch (error) {
      console.log(error);

      return this.getDefaultValueBySpecificKey(key, default_value);
    }
  }

  private static getDefaultValueBySpecificKey(key: string, default_value: any) {
    return default_value;
  }

  static Set(key: string, value: any, expireTimeBySecond = CommonCacheService.CACHE_EXPIRE_TIME_DEFAULT) {
    if (value === undefined || value === null || value === '') {
      return;
    }
    const currentMillisecond = expireTimeBySecond === CommonCacheService.CACHE_EXPIRE_NONE ? CommonCacheService.CACHE_EXPIRE_NONE : dayjs().unix() + expireTimeBySecond;
    const data = {
      value: value,
      expire: currentMillisecond
    };

    try {
      this.LocalStorage.setItem(key, ENCRYPT(JSON.stringify(data)));
    } catch (error) {
      console.log(error);
    }

  }

  public set(key: string, value: any, expireTimeBySecond = CommonCacheService.CACHE_EXPIRE_TIME_DEFAULT) {
    CommonCacheService.Set(key, value, expireTimeBySecond);
  }

  public clear(key: string) {
    CommonCacheService.Clear(key);
  }

  static Clear(key: string) {
    this.LocalStorage.removeItem(key);
  }

  public clearAll() {
    CommonCacheService.ClearAll();
  }

  static ClearAll() {
    this.LocalStorage.clear();
  }

  static DeleteKeyStartWith(text: string) {
    const keys = Object.keys(CommonCacheService.LocalStorage);

    if (!keys || !keys.length) {
      return;
    }

    keys.forEach(key => {
      if (key.startsWith(text)) {
        this.Clear(key);
      }
    });
  }
}
