import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { APP_CONFIG } from "../constants/config";

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend) // Translation files in /public/locales
  .use(initReactI18next)
  .use(LanguageDetector) // detect language setting of browser
  .init({
    returnNull: false,
    fallbackLng: APP_CONFIG.DEFAULT_LANGUAGE,
    saveMissing: true, // if key not found, return a fallback text
    lng: localStorage.getItem(APP_CONFIG.LS_LANGUAGE) || APP_CONFIG.DEFAULT_LANGUAGE,
    debug: false,
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;
