import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { ReactComponent as ChipheartSearch } from "@/assets/icons/chipheart-search.svg";

import "./pastPatients.scss";
import { IPastPatient } from "@/services/objects/patient/patient.interface";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { listPatientAsyncThunk } from "@/redux/reducer/patientSlice";
import { debounce } from "lodash";
import { IHttpResponse } from "@/types/http-response.interface";
import { Text, Tooltip } from "@chakra-ui/react";
import { patientState } from "@/redux/store";
import { listPastPatient } from "@/services/objects/patient/patient.service";
import { setFullForm } from "@/redux/reducer/toolSlice";
import { ROUTE } from "@/constants/route";
import { useNavigate } from "react-router-dom";

interface ITableItemProps {
  patient: IPastPatient;
  index: number;
  style: CSSProperties;
  onClick: () => void
}

interface ITableConfig {
  fieldName: string;
  hasIndex?: boolean;
  fieldKey: string;
  style: CSSProperties
}

const TableConfig = (): Array<ITableConfig> => {
  return [
    {
      fieldName: '',
      hasIndex: true,
      fieldKey: 'name',
      style: { width: '30%', display: 'flex', alignItems: 'center' }
    },
    {
      fieldName: 'DOS',
      fieldKey: 'dos',
      style: { width: '20%' }
    },
    {
      fieldName: 'Last edited',
      fieldKey: 'updated_at',
      style: { width: '20%' }
    },
    {
      fieldName: 'Date of file creation',
      fieldKey: 'created_at',
      style: { width: '30%' }
    }
  ];
}

const PastPatientsCard: React.FC = () => {
  const dispatch = useAppDispatch();
  const [keySearch, setKeySearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loadmore, setLoadMore] = useState<boolean>(true);
  const [patients, setPatients] = useState<Array<IPastPatient>>([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const configs = TableConfig();


  const { listOfPatient, isLoading } = useAppSelector(patientState);

  const handlerSearch = (event: any) => {
    const value = event.target.value || '';

    const search = `${value}`.trim();
    setPatients([]);

    setKeySearch(search);
  }

  useEffect(() => {
    setLoading(true);
    listPastPatient({ keyword: keySearch, per_page: 100, page: 1 }).then((result) => {
      if (!result?.data) {
        return;
      }
      if (!result.data || !result.data.length) {
        return;
      }
      setHasMore((result.data || []).length > 0);
      setPatients((prev) => [...prev, ...(result.data || [])]);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  }, [keySearch]);

  // const onScroll = (e: any) => {
  //   const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

  //   if (!bottom || !hasMore || loading) {
  //     return;
  //   }

  //   console.log('vao day bottom',);

  //   setPage((prev) => prev + 1);
  // };

  const handlerClickPatient = (patient: IPastPatient) => {
    console.log(patient);

    dispatch(setFullForm(true));
    navigate(`${ROUTE.PATIENT_ADD.to}?history_id=${patient.id}`);
  }

  return (
    <div>
      <div className="header">
        <div style={{ fontSize: '22px', fontWeight: 600 }}>Past Patients</div>

        <div
          className="flex w-[183px] flex-row gap-x-[10px] rounded-[8px] p-2 items-center"
          style={{ border: "1px solid #F0F0F0", }}>
          <ChipheartSearch />
          <input
            type="text"
            placeholder="Patient's Name"
            className="w-full"
            onChange={handlerSearch} />
        </div>
      </div>
      <div className="content">
        {patients.map((patient, idx) => {
          return (
            <div key={patient.id}
              className="table-item-container"
              style={{ paddingTop: '12px', paddingBottom: '12px' }}
              onClick={() => handlerClickPatient(patient)}>
              {configs.map((config) => {
                return (
                  <div key={config.fieldKey} style={{ ...(config.style || {}) }}>
                    {config.hasIndex && <span className="patient-index">{idx + 1}</span>}

                    {config.fieldName && <div>
                      <div style={{ fontSize: '13px', fontWeight: 400, color: "#969799" }}>{config.fieldName}</div>
                    </div>}

                    <Tooltip
                      label={(patient as any)[config.fieldKey]}
                      hasArrow>
                      <Text noOfLines={1} style={{ fontSize: '15px', fontWeight: 400 }}>{(patient as any)[config.fieldKey]}</Text>
                    </Tooltip>
                  </div>
                )
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const PastPatientsCardWrapper = () => {
  return <PastPatientsCard />;
};

export default PastPatientsCardWrapper;
