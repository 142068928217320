import { PAGE_LIMITS } from "./picklist";
import type { APIQuery, RouteSchema } from "types/global";

export const DEFAULT_PAGE_QUERY: APIQuery = {
  page: 1,
  max_results: PAGE_LIMITS[0],
};

export const DEFAULT_META: APIQuery = {
  total: 0,
  skip: 0,
  limit: 10,
};

export const ROUTE: Record<string, RouteSchema> = {
  PATIENT: {
    to: "/patient",
    path: "patient",
  },
  PATIENT_TOOL: {
    to: "/patient/tool",
    path: "patient/tool",
  },
  PATIENT_ADD: {
    to: "/patient/add",
    path: "patient/add",
  },
  PATIENT_LIST: {
    to: "/patient/list",
    path: "patient/list",
  },
  PATIENT_EXPORT_IMPORT: {
    to: "/patient/export-import",
    path: "patient/export-inmport",
  },
  HOME: {
    to: "/",
  },
  DASHBOARD: {
    to: "/dashboard",
    path: "dashboard",
  },
  LOGIN: {
    to: "/login",
    path: "login",
  },
  CHANGE_PASSWORD: {
    to: "/change-password",
    path: "change-password",
  },
};

export const NAVBAR_ITEMS = [ROUTE.AI_PIPELINE, ROUTE.ANALYTIC_GENERAL, ROUTE.USERS, ROUTE.SETTING];
