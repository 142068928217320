const CardComponent = (props: any) => {
  return (
    <div
      className="px-[32px] py-[32px]"
      style={{
        backgroundColor: "white",
        borderRadius: "16px",
        minHeight: "320px",
        width: "100%",
        ...props.styleContainer,
      }}
    >
      {props.children}
    </div>
  );
};

export default CardComponent;
