import { UseToastOptions, useToast } from "@chakra-ui/toast";

const defaultConfigs: UseToastOptions = {
  position: "top-right",
  duration: 5000,
  isClosable: true
};

export default function useNotifier(config?: UseToastOptions) {
  const toast = useToast({
    ...defaultConfigs,
    ...config
  });
  return toast;
}
