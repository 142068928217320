import { UploadStatus, FileStatus } from "types/file";
import { FormSteps, LanguageCode, QueryOperator } from "types/global";
import type { UserRole, UserRoleID } from "types/user";

export const LANGUAGE_CODE: Record<string, LanguageCode> = {
  ENGLISH: "en",
  JAPANESE: "ja",
};

export const LANGUAGE_TEXT = {
  [LANGUAGE_CODE.ENGLISH]: "English",
  [LANGUAGE_CODE.JAPANESE]: "日本語",
};

export const DATE_FORMAT = {
  YYYY_MMM: "YYYY MMM", // 2019 Oct
  MMM_DD_YYYY: "MMM, DD YYYY", // Oct, 05 2019
  YYYY_MM_DD_DOT: "YYYY.MM.DD", // 2019.10.05
  YYYY_MM_DD_HH_MM_DOT: "YYYY.MM.DD hh:mm", // 2019.10.05 17:43
  YYYY_MM_DD_HH_MM_SS_DOT: "YYYY.MM.DD hh:mm:ss", // 2019.10.05 17:43:30
  YYYY_MM_DD_SLASH: "YYYY/MM/DD", // 2019/10/05
  YYYY_MM_DD_HH_MM_SLASH: "YYYY/MM/DD hh:mm", // 2019/10/05 17:43
  YYYY_MM_DD_HH_MM_SS_SLASH: "YYYY/MM/DD hh:mm:ss", // 2019/10/05 17:43:30
  DD_MM_YYYY_SLASH: "DD/MM/YYYY", //24/03/2022,
  YYYYMMDDhhmmss: "YYYYMMDDhhmmss",
};

export const WEEKDAY_SHORT = {
  1: "DATE.MONDAY_ALT",
  2: "DATE.TUESDAY_ALT",
  3: "DATE.WEDNESDAY_ALT",
  4: "DATE.THURSDAY_ALT",
  5: "DATE.FRIDAY_ALT",
  6: "DATE.SATURDAY_ALT",
  7: "DATE.SUNDAY_ALT",
};

export const MONTH_SHORT: Record<number, string> = {
  1: "DATE.JANUARY_ALT",
  2: "DATE.FEBRUARY_ALT",
  3: "DATE.MARCH_ALT",
  4: "DATE.APRIL_ALT",
  5: "DATE.MAY_ALT",
  6: "DATE.JUNE_ALT",
  7: "DATE.JULY_ALT",
  8: "DATE.AUGUST_ALT",
  9: "DATE.SEPTEMBER_ALT",
  10: "DATE.OCTOBER_ALT",
  11: "DATE.NOVEMBER_ALT",
  12: "DATE.DECEMBER_ALT",
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  DELETED: "DELETED",
};

export const USER_ROLE: Record<string, UserRole> = {
  ADMIN: "PrimaryAdmin",
  USER: "User",
};

export const USER_ROLE_ID: Record<string, UserRoleID> = {
  ADMIN: 1,
  USER: 2,
};

export const USER_ROLE_TEXT = {
  [USER_ROLE.ADMIN]: "USERS.ADMIN",
  [USER_ROLE.USER]: "USERS.USER",
};

export const USER_ROLE_ID_TEXT = {
  [USER_ROLE_ID.ADMIN]: "USERS.ADMIN",
  [USER_ROLE_ID.USER]: "USERS.USER",
};

export const HTTP_CODE = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
};

export const STATUS_TEXT: Record<FileStatus, string> = {
  [FileStatus.PENDING]: "COMMON.STATUS_PENDING",
  [FileStatus.DONE]: "COMMON.STATUS_DONE",
  [FileStatus.ERROR]: "COMMON.STATUS_ERROR",
  [FileStatus.NEED_TO_REVIEW]: "COMMON.STATUS_NEED_TO_REVIEW",
  [FileStatus.PROCESSING]: "COMMON.STATUS_PROCESSING",
};

export const KEY_CODE = {
  ENTER: 13,
  SPACE: 32,
  Y: 89,
  Z: 90,
};

export const KEYBOARD_KEY = {
  ENTER: "Enter",
  SPACE: "Space",
  Y: "KeyY",
  Z: "KeyZ",
};

export const UPLOAD_STATUS: Record<Uppercase<UploadStatus>, UploadStatus> = {
  FAILED: "failed",
  PENDING: "pending",
  UPLOADING: "uploading",
  SUCCESS: "success",
};

export const UPLOAD_STATUS_TEXT = {
  [UPLOAD_STATUS.FAILED]: "COMMON.UPLOAD_STATUS_FAILED",
  [UPLOAD_STATUS.PENDING]: "COMMON.UPLOAD_STATUS_PENDING",
  [UPLOAD_STATUS.UPLOADING]: "COMMON.UPLOAD_STATUS_UPLOADING",
  [UPLOAD_STATUS.SUCCESS]: "COMMON.UPLOAD_STATUS_SUCCESS",
};

export const QUERY_OPERATOR: Record<string, QueryOperator> = {
  EQUAL: "$eq",
  LIKE: "$like",
  IN: "$in",
};

export type GlobalEvent = "logout";

export const GLOBAL_EVENTS: Record<Uppercase<GlobalEvent>, GlobalEvent> = {
  LOGOUT: "logout",
};

export const TABLE_CELL_TYPE = {
  ACTION: "action",
  CHECKBOX: "checkbox",
  INDEX: "index",
  STATUS: "status",
  TEXT: "text",
};

export const REFRESH_TOKEN_STORAGE_NAME = "refresh_token";

export const TOKEN_STORAGE_NAME = "token";

export const NO_ACCOUNT_FOUND_SERVER_ERROR = "No active account found with the given credentials";

export const INCORRECT_USER_LOGIN = "COMMON.INCORRECT_LOGIN_INFO";

export const FORM_STEP_ID_PREFIX = "formId-";

export const TableOfForms = [
  {
    label: "SCAI Risk Information",
    step: FormSteps.SCAIRiskInformationForm,
  },
  {
    label: "Syntax Score",
    step: FormSteps.SyntaxScoreDeclaration,
  },
  {
    label: "ECABG (Emergency Coronary Bypass Graft Surgery)",
    step: FormSteps.ECABGForm,
  },
  {
    label: "STS Score (Society of Thoracic Surgeons Score)",
    step: FormSteps.STSForm,
  },
  {
    label: "Impella support Indication",

    step: FormSteps.ImpellaForm,
  },
  // {
  //   label: "Dynamic Risk Prediction of Outcomes After ECABG",
  //   step: FormSteps.RiskScoreForm,
  // },
  {
    label: "British PCI CHIP Score",
    step: FormSteps.BritishPCIForm,
  },
  {
    label: "Procedural Risk Stratification",
    step: FormSteps.ProceduralRiskStratificationForm,
  },
  {
    label: "Procedural Characteristics",
    step: FormSteps.ProceduralCharacteristicsForm,
  },
  {
    label: "Follow up",
    step: FormSteps.FollowUpForm,
  },
  {
    label: "Documentation",
    step: FormSteps.DocumentaryForm,
  },
];

export const STEMI = "STEMI";
export const YES = "Yes";
export const NO = "No";
export const URGENT = "Urgent";
export const EMERGENT = "Emergent";
export const TRANSIENT_SHOCK = "Transient shock";
export const SUSTAINED_SHOCK = "Sustained shock";
export const NYHA_IV = "IV";
export const NONE = "None";
export const FEMALE = "Female";
