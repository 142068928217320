import axios, { AxiosHeaders, AxiosResponse } from "axios";
import { ILoginResponse, IUserLogin } from "../interfaces/authen/authen.interface";
import { HOST_ADM } from "@/constants/host-domain";
import { request } from "@/helper/http";
import { CacheKeys } from "@/utils/key-cache";
import { CommonCacheService } from "./cache.service";
import { IAccount } from "../objects/account/account.interface";

export class CommonAuthenticationService {
  static authenticate(params: { [key: string]: any }, body: IUserLogin): Promise<AxiosResponse<ILoginResponse, any>> {
    let url = `${HOST_ADM()}login`;
    const bodyMap: any = {};
    const header = {
      'Content-Type': 'application/json',
    };
    const headers = new AxiosHeaders(header);

    for (const key in body) {
      if ((body as any)[key]) {
        bodyMap[key] = (body as any)[key].trim();
      }
    }

    return axios.post(url, bodyMap, { headers, params });
  }

  static getMyAccount(): IAccount {
    return CommonCacheService.Get(CacheKeys.KEY_CACHE_ACCOUNT);
  }

  static logout() {
    let url = `${HOST_ADM()}logout`;

    return request({
      method: "POST",
      url,
      data: {},
    });
  }
}