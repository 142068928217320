import { domainWeb, domainPublic, domainApi } from "@/types/key-replace";

export const DOMAIN_SITE = (): string => {
  return domainWeb;
};

export const DOMAIN_PUBLIC = (): string => {
  return domainPublic;
};

export const DOMAIN_GET_SOURCES_STATIC = (): string => {
  // if (location.host.search(/^(test)[0-9]+(.mobio.vn)/) > -1) {
  //   return `https://test1.mobio.vn/static/`;
  // }

  return `${DOMAIN_SITE()}/static/`;
};

export const DOMAIN_GET_SOURCES_STATIC_TEMPLATE_EXCEL = (): string => {
  return DOMAIN_GET_SOURCES_STATIC();
};


export const BASE_PATH = (): string => {
  return domainApi;
};

export const HOST_BASE = () => {
  return `${BASE_PATH()}api/`;
};

export const HOST_PATIENT = () => {
  const host = `${BASE_PATH()}/api/`;

  return host;
};

export const HOST_ADM = () => {
  const host = `${BASE_PATH()}/api/`;

  return host;
};
