import React, { ReactNode, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { LoaderFull } from "@/common";
import { useAppSelector } from "@/redux/hook";
import { userState } from "@/redux/store";
import { ROUTE } from "@/constants/route";
import { PageLoader } from "@/common";
import DashboardPage from "@/pages/Dashboard/DashboardPage";

const LoginPage = React.lazy(() => import("@/pages/Login/Login"));
const PatientPage = React.lazy(() => import("@/pages/Patient/PatientPage"));
const PatientAddPage = React.lazy(() => import("@/pages/Patient/PatientAddPage"));
const PatientListPage = React.lazy(() => import("@/pages/Patient/PatientListPage"));
const PatientExportImport = React.lazy(() => import("@/pages/Patient/PatientExportImport"));

const AuthLayout = React.lazy(() => import("@/layouts/AuthLayout"));
const UnAuthLayout = React.lazy(() => import("@/layouts/UnAuthLayout"));

interface RouteType {
  name?: string;
  children?: ReactNode;
}

const Route: React.FC<RouteType> = (): JSX.Element => {
  const { isInitialized, isAuthenticated, user } = useAppSelector(userState);

  const isAuth = isAuthenticated && user;

  // console.log(isAuthenticated, user);

  // const isAuthManagement = isAuth && hasRole(scope, ROLE.VIEW_USER);
  // const isAuthSystemSetting = isAuth && hasRole(scope, ROLE.VIEW_SYSTEM_SETTING);

  // const unAuthRoutes = {
  //   path: ROUTE.LOGIN.to,
  //   element: !isAuth ? <UnAuthLayout /> : <Navigate to={ROUTE.HOME.to} />,
  //   children: [{ path: "", element: <LoginPage /> }],
  // };

  const authRoutes = {
    path: ROUTE.HOME.to,
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: (
          <Navigate
            to={{
              pathname: "dashboard",
            }}
          />
        ),
      },
      {
        path: "",
        element: (
          <Navigate
            to={{
              pathname: "dashboard",
            }}
          />
        ),
      },
      {
        path: ROUTE.DASHBOARD.path,
        element: <DashboardPage />,
      },
      {
        path: ROUTE.PATIENT_TOOL.path,
        element: <PatientAddPage />
      },
      {
        path: ROUTE.PATIENT_ADD.path,
        element: isAuth ? <PatientAddPage /> : <Navigate to={ROUTE.DASHBOARD.to} />
      },
      {
        path: ROUTE.PATIENT.path,
        element: <PatientPage />,
        children: [
          { path: "", element: <Navigate to={ROUTE.PATIENT_ADD.to} /> },
          { path: "list", element: <PatientListPage /> },
          { path: "add", element: <PatientAddPage /> },
          { path: "export-import", element: <PatientExportImport /> },
        ],
      },
    ],
  };

  const routing = useRoutes([{
    path: ROUTE.LOGIN.path,
    element: !isAuth ? <LoginPage /> : <Navigate to={ROUTE.DASHBOARD.to} />,
  }, authRoutes]);

  if (!isInitialized) {
    return <LoaderFull />;
  }

  return <Suspense fallback={<PageLoader />}>{routing}</Suspense>;
};

export default Route;
