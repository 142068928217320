import React, { useRef } from "react";
import { ButtonGroup } from "@chakra-ui/button";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay
} from "@chakra-ui/modal";

import type { IAlertModal } from "@/types/modal";

const Modal: React.FC<Partial<IAlertModal>> = ({
  show,
  text,
  title,
  fetching,

  onYes,
  yesText,

  onOk,
  okText,

  onCancel,
  cancelText
}) => {
  const { t } = useTranslation();
  const onClose = onCancel || onOk;

  const cancelRef = useRef(null);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose as any}
      isOpen={show as any}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton onClick={onClose} />
        <AlertDialogBody>{text}</AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            {onCancel && (
              <Button colorScheme="blue" variant="outline" ref={cancelRef} onClick={onCancel} disabled={fetching}>
                {cancelText || t("COMMON.CANCEL")}
              </Button>
            )}
            {onYes && (
              <Button colorScheme="extraBlue.400" onClick={onYes} disabled={fetching} isLoading={fetching}>
                {yesText || t("COMMON.YES")}
              </Button>
            )}
            {onOk && (
              <Button colorScheme="green" onClick={onOk} isLoading={fetching} disabled={fetching}>
                {okText || t("COMMON.OK")}
              </Button>
            )}
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Modal;
