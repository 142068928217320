import { APIQuery } from "@/types/global";
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import * as workflowService from "services/workflow";
import { Workflow } from "types/workflow";
import { uniqBy } from "lodash";

export const WORKFLOW_LIMIT = 30;

export interface WorkflowState {
  workflows: Workflow[];
  loading: boolean;
  hasMore: boolean;
  offset: number;
}
const initialState: WorkflowState = {
  workflows: [],
  loading: true,
  hasMore: true,
  offset: 0
};

export const getWorkflowsThunk = createAsyncThunk("workflows/getList", async (query: APIQuery) => {
  const data = await workflowService.getWorkflows(query);
  return data;
});

export const getAllWorkflowsThunk = createAsyncThunk("workflows/getAllList", async () => {
  const data = await workflowService.getAllWorkflows();
  return data;
});

export const workflowSlice: Slice<WorkflowState> = createSlice({
  name: "workflow",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkflowsThunk.fulfilled, (state, action) => {
        state.workflows = uniqBy([...state.workflows, ...action.payload.results], "id");
        state.loading = false;
        state.hasMore = !!action.payload.next;
        state.offset += WORKFLOW_LIMIT;
      })
      .addCase(getWorkflowsThunk.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllWorkflowsThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllWorkflowsThunk.fulfilled, (state, action) => {
        state.workflows = uniqBy([...action.payload], "id");
        state.loading = false;
      })
      .addCase(getAllWorkflowsThunk.rejected, (state, action) => {
        state.loading = false;
      });
  }
});

export default workflowSlice.reducer;
