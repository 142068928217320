import { ReactComponent as ScaiIcon } from "../icons/scai.svg";
import { ReactComponent as EcabgIcon } from "../icons/ecabg.svg";
import { ReactComponent as SyntaxIcon } from "../icons/syntax.svg";
import { ReactComponent as StsIcon } from "../icons/sts.svg";
import { ReactComponent as ImpellaIcon } from "../icons/impella.svg";
import { ReactComponent as PciChipIcon } from "../icons/pci_chip.svg";
import { ReactComponent as ProCharIcon } from "../icons/pro_char.svg";
import { ReactComponent as PropRiskIcon } from "../icons/pro_risk.svg";
import { ReactComponent as FollowUpIcon } from "../icons/follow_up.svg";
import { ReactComponent as DocIcon } from "../icons/doc.svg";
import { IToolConfig } from "../interfaces/tool.interface";
import { FormSteps, SingleFormSteps } from "@/types/global";

export const ListOfTools = (): Array<IToolConfig> => {
  return [
    {
      key: SingleFormSteps.SCAIRiskInformationForm,
      form: FormSteps.SCAIRiskInformationForm,
      label: "SCAI Risk Information",
      icon: <ScaiIcon />,
    },
    {
      key: SingleFormSteps.SyntaxScoreDeclarationI,
      form: FormSteps.SyntaxScoreDeclaration,
      label: "Syntax Score I",
      icon: <SyntaxIcon />,
      splitKey: 1
    },
    {
      key: SingleFormSteps.SyntaxScoreDeclarationII,
      form: FormSteps.SyntaxScoreDeclaration,
      label: "Syntax Score II",
      icon: <SyntaxIcon />,
      splitKey: 2
    },
    {
      key: SingleFormSteps.ECABGForm,
      form: FormSteps.ECABGForm,
      label: "ECABG",
      icon: <EcabgIcon />,
    },
    {
      key: SingleFormSteps.STSForm,
      form: FormSteps.STSForm,
      label: "STS Score",
      icon: <StsIcon />,
    },
    {
      key: SingleFormSteps.ImpellaForm,
      form: FormSteps.ImpellaForm,
      label: "Impella Support Indication",
      icon: <ImpellaIcon />,
    },
    {
      key: SingleFormSteps.BritishPCIForm,
      form: FormSteps.BritishPCIForm,
      label: "British PCI CHIP Scoresyn",
      icon: <PciChipIcon />,
    },
    {
      key: SingleFormSteps.ProceduralRiskStratificationForm,
      form: FormSteps.ProceduralRiskStratificationForm,
      label: "Procedural Risk Stratification",
      icon: <PropRiskIcon />,
    },
    // {
    //   key: FormSteps.ProceduralCharacteristicsForm,
    //   label: "Procedural Characteristics",
    //   icon: <ProCharIcon />,
    // },
    // {
    //   key: FormSteps.FollowUpForm,
    //   label: "Patient Follow Up",
    //   icon: <FollowUpIcon />,
    // },
    // {
    //   key: FormSteps.DocumentaryForm,
    //   label: "Documentation",
    //   icon: <DocIcon />,
    // },
  ];
};
