import { IParamsGetDetail, IParamsGetListPastPatients, IPatient } from "@/services/objects/patient/patient.interface";
import { detailPastPatient, listPastPatient, listPatient } from "@/services/objects/patient/patient.service";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface InitialPatientState {
  isLoading: boolean,
  errorMessage: string;
  listOfPatient: Array<IPatient>;
  query: IParamsGetListPastPatients;
  currentPatient: IPatient | undefined;
};

export const listPatientAsyncThunk = createAsyncThunk(
  "patient/list", async (params: IParamsGetListPastPatients, { rejectWithValue }) => {
    try {
      const result = await listPastPatient(params);
      if (!result) {
        return {
          data: []
        }
      }

      return result;
    } catch (err) {
      return rejectWithValue(JSON.stringify(err as any));
    }
  }
);


export const patientSlice = createSlice({
  name: 'patients',

  // Thêm 1 số state như trạng thái loading, báo lỗi và thông tin user đang đăng nhập
  initialState: {
    isLoading: false,
    errorMessage: '',
    listOfPatient: [],
    query: { per_page: 200 },
    currentPatient: undefined
  } as InitialPatientState,

  // Các action bình thường (sync action)
  reducers: {
    updateFilter: (state, { payload }: PayloadAction<IParamsGetListPastPatients>) => {
      state.query = payload;
      // dispatch(listPatientAsyncThunk(payload)).then((res) => {
      //   console.log(res);
      //   // setData(res.payload.);
      // });
    },
    updateCurrentPatient: (state, { payload }: PayloadAction<IPatient>) => {
      state.currentPatient = payload;
      // dispatch(listPatientAsyncThunk(payload)).then((res) => {
      //   console.log(res);
      //   // setData(res.payload.);
      // });
    },
  },

  // Code logic xử lý async action
  extraReducers: (builder) => {
    // Bắt đầu thực hiện action login (Promise pending)
    builder.addCase(listPatientAsyncThunk.pending, (state) => {
      // Bật trạng thái loading
      state.isLoading = true;
    });

    // Khi thực hiện action login thành công (Promise fulfilled)
    builder.addCase(listPatientAsyncThunk.fulfilled, (state, action) => {
      // Tắt trạng thái loading, lưu thông tin user vào store
      state.isLoading = false;
      state.listOfPatient = action.payload.data as Array<IPatient> || [];
    });

    // Khi thực hiện action login thất bại (Promise rejected)
    builder.addCase(listPatientAsyncThunk.rejected, (state, action) => {
      // Tắt trạng thái loading, lưu thông báo lỗi vào store
      state.isLoading = false;
      // state.errorMessage = action.payload.message;
    });
  },
});

export const { updateFilter, updateCurrentPatient } = patientSlice.actions;

export default patientSlice.reducer;
