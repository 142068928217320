import "./tool.scss";

const ButtonToolComponent = (props: { children?: React.ReactNode; onClick?: () => void; label: string }) => {
  return (
    <div className="button-tool-container" onClick={props.onClick}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.children}
        <span
          style={{
            fontSize: "17px",
            fontWeight: "600",
            paddingTop: "24px",
          }}
        >
          {props.label}
        </span>
      </div>
    </div>
  );
};

export default ButtonToolComponent;
