import React, { useCallback, useEffect } from "react";
import { Box } from "@chakra-ui/layout";
import { AlertDialog, LoaderFull } from "./common";
import { APP_CONFIG } from "@/constants/config";
import { useModal } from "./contexts/ModalProvider";
import { useAppSelector } from "@/redux/hook";
import { getSessionInfo } from "@/redux/reducer/userSlice";
import { useAppDispatch } from "@/redux/hook";
import { userState } from "@/redux/store";
import { useTranslation } from "react-i18next";

import Route from "@/routes";

const App = () => {
  const dispatch = useAppDispatch();
  const { isInitialized } = useAppSelector(userState);
  const { i18n } = useTranslation();
  const { modal, showModal, modalFetching } = useModal();

  const _changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
      localStorage.setItem(APP_CONFIG.LS_LANGUAGE, lang);
    },
    [i18n]
  );

  useEffect(() => {
    const persistLanguage = localStorage.getItem(APP_CONFIG.LS_LANGUAGE);
    if (persistLanguage) {
      _changeLanguage(persistLanguage);
    }
  }, [_changeLanguage]);

  React.useEffect(() => {
    dispatch(getSessionInfo());
  }, [dispatch]);

  if (!isInitialized) return <LoaderFull />;

  return (
    <Box maxW="100%" id="global-wrapper">
      <AlertDialog show={showModal} {...modal} fetching={modalFetching} />
      <Route />
    </Box>
  );
};

// export default process.env.NODE_ENV === 'development' ? hot(App) : App;

export default App;
