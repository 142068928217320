import { FormSteps } from "@/types/global";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FormState {
  currentTool?: FormSteps;
  fullForm: boolean;
}


const initialState: FormState = {
  currentTool: FormSteps.SCAIRiskInformationForm,
  fullForm: true
};

export const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setCurrentTool: (state, { payload }: PayloadAction<FormSteps>) => {
      state.currentTool = payload;
    },
    setFullForm: (state, { payload }: PayloadAction<boolean>) => {
      state.fullForm = payload;
    }
  },
});

export const { setCurrentTool, setFullForm } = toolsSlice.actions;

export default toolsSlice.reducer;
