import { LANGUAGE_CODE } from "./global";

export const LANGUAGES = [LANGUAGE_CODE.ENGLISH];

export const PAGE_LIMITS = [10, 50, 100];

export enum ROLE {
  VIEW_USER = "view_user",
  CREATE_WORKFLOW = "add_workflow",
  EDIT_WORKFLOW = "change_workflow",
  DELETE_WORKFLOW = "delete_workflow",
  VIEW_SYSTEM_SETTING = "view_systemsettings",
}
