import { ROLE } from "@/constants/picklist";
import React from "react";
import { UserRoleID } from "./user";

export type QueryOperator = "$eq" | "$like" | "$in";
export type Sorting = "asc" | "desc";

export interface LocalStorageType {
  access_token: string;
  language?: "EN" | "JP";
}

// Use type string for unknow language code
export type LanguageCode = "en" | "ja" | string;

export interface APIQuery {
  total?: number;
  limit?: number;
  sort?: string;
  skip?: number;
  page?: number;
  filter?: Record<string, any>;
  search?: string;
  term?: string;
  max_results?: number;
  offset?: number;
  type?: string;
  ordering?: string;
}

export interface APIResponse {
  count: number;
  previous: string;
  next: string;
  results: any;
}

export interface ChildrenType {
  children: React.ReactChild | React.ReactChildren;
}

export interface SelectOption {
  label: string;
  value: string | number;
  name?: string;
  display_name?: string;
}

export interface RouteSchema {
  to: string;
  path?: string;
  icon?: React.ReactNode;
  text?: string;
  search?: string;
  authorized?: UserRoleID[];
  role?: ROLE;
}

export interface StepProp {
  step: number;
  isActive: boolean;
  title: any;
  description?: any;
  id?: string;
  part?: string;
}

export interface WorkflowAPIQuery {
  code_name?: string;
  description?: string;
  display_name?: string;
  pipeline?: string;
  target_fields?: any;
  is_public?: boolean;
  users?: any;
  team?: any;
}


export enum FormSteps {
  SCAIRiskInformationForm,
  SyntaxScoreDeclaration,
  ECABGForm,
  STSForm,
  ImpellaForm,
  // RiskScoreForm,
  BritishPCIForm,
  ProceduralRiskStratificationForm,
  ProceduralCharacteristicsForm,
  FollowUpForm,
  DocumentaryForm,
  FinalFormReview
}

export enum SingleFormSteps {
  SCAIRiskInformationForm,
  SyntaxScoreDeclarationI,
  SyntaxScoreDeclarationII,
  ECABGForm,
  STSForm,
  ImpellaForm,
  // RiskScoreForm,
  BritishPCIForm,
  ProceduralRiskStratificationForm,
  // ProceduralCharacteristicsForm,
  // FollowUpForm,
  // DocumentaryForm,
  // FinalFormReview,
}
