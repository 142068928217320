// @ts-nocheck

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ErrorBoundary } from "common";
import CombinedProvider from "contexts/CombinedProvider";
import ErrorPage from "pages/Error";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "redux/store";
import App from "./App";

import "helper/i18n";
import "typeface-inter";
import "./index.scss";
import "./print.scss";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  fonts: {
    body: "Open Sans, sans-serif",
    headings: "Open Sans, sans-serif",
  },

  // Define new colorScheme
  colors: {
    primary: "#F55B5B",
    textGray: "#969799",
    bgGray: "#F0F0F0",
    bgDisabled: "#FEEFEF",
    Text: {
      100: "#2D2F33",
      30: "#C0C1C2",
      Disabled: "#8E8E93",
      Primary: "#2D2F33",
      Secondary: "#969799",
    },
    Support: {
      2: "#7EC3B2",
    },
    Brand: {
      Primary: {
        100: "#F55B5B",
        10: "#FEEFEF",
      },
    },
    Neutral: {
      2: "#FCFCF9",
    },
    Border: {
      Disabled: "#F9F9F9",
    },
    Status: {
      Alert: "#FFCE22",
      Danger: "#FF6A6A",
      Good: "#07BE66",
    },
    previewBlue: {
      300: "#33ADE1",
    },
    extraBlue: {
      400: { 500: "#4299E1", 200: "#4299E1" },
    },
    extraRed: {
      300: { 500: "#FC8181", 200: "#FC8181" },
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <ErrorBoundary fallback={<ErrorPage />}>
              <CombinedProvider>
                <SnackbarProvider
                  anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                  }}
                  autoHideDuration={3000}
                >
                  <App />
                </SnackbarProvider>
              </CombinedProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </ChakraProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
