import { ReactComponent as DashboardIcon } from "@/assets/icons/dashboard.svg";
import { ReactComponent as ChipHeartImage } from "@/assets/imgs/chipheart_img.svg";
import CardComponent from "@/common/Card/card";
import { ROUTE } from "@/constants/route";
import { useAppSelector } from "@/redux/hook";
import { setCurrentTool, setFullForm } from "@/redux/reducer/toolSlice";
import { userState } from "@/redux/store";
import { Button, Grid, GridItem } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonToolComponent from "./ButtonTool/ButtonTool";
import PastPatientsCardWrapper from "./PastPatients/PastPatients";
import RefCardComponent from "./RefCard/RefCard";
import { ListOfTools } from "./defines/tools.define";
import { IToolConfig } from "./interfaces/tool.interface";

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useAppSelector(userState);

  const handlerToolClick = (tool: IToolConfig) => {
    dispatch(setFullForm(false));
    dispatch(setCurrentTool(tool.key));
    navigate(`${ROUTE.PATIENT_TOOL.to}`);
  };

  const welcome = () => {
    const now = dayjs().hour();

    if (now > 0 && now < 12) {
      return 'Good Morning';
    }

    if (now > 12 && now < 18) {
      return 'Good Evening';
    }

    return 'Good Afternoon';
  }

  return (
    <div className="w-full" style={{ height: `calc(100vh - 81px)`, overflowX: "hidden" }}>
      <div className="h-[64px] w-full bg-[#fff] px-[24px] py-[12px] flex items-center w-full justify-between">
        <div className="flex items-center">
          <DashboardIcon />
          <span style={{
            fontSize: '15px',
            fontWeight: 600,
            paddingLeft: '4px'
          }}>Dashboard</span>
        </div>

        <span style={{
          color: '#33ADE1',
          fontSize: '15px',
          fontWeight: 600
        }}>{welcome()}, {user?.name || 'Guest'}</span>
      </div>
      <div className="flex w-full bg-[#FCF1F1]" style={{ height: `calc(100vh - 145px)`, overflowX: "hidden" }}>
        <div
          className="h-full w-full"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="px-[24px]" style={{ marginTop: "24px", display: "flex", justifyContent: "center" }}>
            <CardComponent styleContainer={{
              marginRight: "12px",
              display: 'flex',
              padding: '32px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                paddingTop: '20px',
                paddingBottom: '20px',
                justifyContent: 'space-between'
              }}>
                <div>
                  <div style={{ fontSize: '29px', fontWeight: 600 }}>
                    What is PCI CHIP Heart?
                  </div>
                  <div style={{ fontSize: '15px', fontWeight: 400, color: '#969799', marginTop: '16px' }}>We are a foundation that help doctors take note of their patients information.</div>
                </div>
                <Button variant="primary" width={'122px'} bgColor={'#F55b5B'} borderRadius={'8px'} fontSize={'17px'} fontWeight={600} color={'#fff'} onClick={() => console.log()}>Read More</Button>
              </div>
              <ChipHeartImage />
            </CardComponent>

            {user && isAuthenticated && <CardComponent styleContainer={{
              marginLeft: "12px",
              padding: '32px'
            }}>
              <PastPatientsCardWrapper />
            </CardComponent>}
          </div>
          <div className="mt-[24px] pl-[24px]">
            <CardComponent styleContainer={{
              padding: '32px', display: 'flex', flexDirection: 'column'
            }}>
              <div style={{ fontSize: '22px', fontWeight: 600 }}>References</div>

              <div className="flex">
                <RefCardComponent title="What is SYNTAX?" />
                <RefCardComponent title="What is SYNTAX?" />
                <RefCardComponent title="What is SYNTAX?" />
                <RefCardComponent title="What is SYNTAX?" />
              </div>
            </CardComponent>
          </div>
          <div className="mt-[24px] px-[24px] pb-[24px]">
            <div>
              <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                {ListOfTools().map((tool, idx) => {
                  return (
                    <GridItem key={tool.key} w="100%">
                      <ButtonToolComponent label={tool.label} onClick={() => handlerToolClick(tool)}>
                        {tool.icon}
                      </ButtonToolComponent>
                    </GridItem>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
