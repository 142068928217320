
import CryptoES from 'crypto-es';
import { en } from './key-encrypt-new';

const getKey = (): string => {

  return en;
};

export const ENCRYPT = (plainData: string): string => {
  const key = CryptoES.enc.Hex.parse(getKey());
  const iv = CryptoES.enc.Hex.parse(getKey());
  const mode = CryptoES.mode.CBC;
  const padding = CryptoES.pad.Pkcs7;
  const options = { iv: iv, mode: mode, padding: padding };

  return CryptoES.AES.encrypt(plainData, key, options).toString();
};

export const DECRYPT = (encryptedData: string): string => {
  const key = CryptoES.enc.Hex.parse(getKey());
  const iv = CryptoES.enc.Hex.parse(getKey());
  const mode = CryptoES.mode.CBC;
  const padding = CryptoES.pad.Pkcs7;
  const options = { iv: iv, mode: mode, padding: padding };

  return CryptoES.AES.decrypt(encryptedData, key, options).toString(CryptoES.enc.Utf8);
};

export const MD5 = (plainData: string) => {
  return CryptoES.MD5(plainData).toString();
};
