import { HOST_BASE } from "@/constants/host-domain";
import { CommonCacheService } from "@/services/common/cache.service";
import { IHttpResponse, IHttpResponsePaging } from "@/types/http-response.interface";
import { CacheKeys } from "@/utils/key-cache";
import axios, { AxiosError, AxiosRequestHeaders, HttpStatusCode, RawAxiosRequestHeaders } from "axios";
import { get, isNil } from "lodash";
import { redirectToLogin } from "./utility";

const instanceAxios = axios.create();

instanceAxios.defaults.baseURL = HOST_BASE();

instanceAxios.interceptors.request.use((config) => {
  const options = getOptions();

  config.headers = options.headers as AxiosRequestHeaders;

  return config;
});

// Response interceptor for API calls
instanceAxios.interceptors.response.use(
  async (response) => {
    return Promise.resolve(handlerResponse(response) as any);
  },
  async (error) => {
    return handlerCatchError(error);
  }
);

export const getOptions = (contentType?: string): { headers: RawAxiosRequestHeaders, params: any } => {
  const headers: RawAxiosRequestHeaders = {};
  const token = CommonCacheService.Get(CacheKeys.KEY_CACHE_TOKEN);
  if (token) {
    headers.Authorization = `Bearer ${token}`;
    headers["Content-Type"] = contentType || 'application/json';
    headers["Accept"] = "application/json";
  }

  return { headers, params: {} };
}


const handlerResponse = <TypeResponse>(res: any): IHttpResponse<TypeResponse> => {
  const resConvert: any = {
    message: get(res, 'message'),
    code: 200
  };
  const code = get(res, 'code', 200);

  if (`${code}` !== '200' && `${code}` !== '001') {
    resConvert.feCloneCode = code;
  }
  const paging: IHttpResponsePaging = {
    page: get(res, 'paging.page'),
    per_page: get(res, 'paging.per_page'),
    total_items: get(res, 'paging.total_items') || get(res, 'paging.total_count') || get(res, 'paging.total_item'),
    total_pages: get(res, 'paging.total_pages') || get(res, 'paging.total_page') || get(res, 'paging.page_count'),
    before: get(res, 'paging.cursors.before') || get(res, 'paging.before') || get(res, 'cursors.before') || get(res, 'paging.before_token'),
    after: get(res, 'paging.cursors.after') || get(res, 'paging.after') || get(res, 'cursors.after') || get(res, 'paging.after_token') || get(res, 'paging.cursors.after_token'),
    previous: get(res, 'paging.previous') || get(res, 'paging.cursors.previous') || get(res, 'cursors.previous'),
    next: get(res, 'paging.next') || get(res, 'paging.next_page') || get(res, 'paging.cursors.next') || get(res, 'cursors.next')
  };
  const keysPaging = Object.keys(paging);

  keysPaging.forEach((key: string) => {
    if (isNil((paging as any)[key])) {
      delete (paging as any)[key];

      return;
    }
  });

  if (Object.keys(paging).length) {
    if (paging.total_items && paging.page && paging.per_page && (paging.page * paging.per_page >= paging.total_items)) {
      paging.total_pages = paging.page;
    }
    if (resConvert.data instanceof Array && !resConvert.data.length) {
      paging.total_pages = paging.page || 0;
    }
    resConvert.paging = paging;
  }
  const fieldDelete = ['code', 'message', 'paging'];

  fieldDelete.forEach(field => {
    if (!isNil(res[field])) {
      delete res[field];
    }
  });
  const fieldNotMergeToData = ['lang'];

  fieldNotMergeToData.forEach(field => {
    if (!isNil(res[field])) {
      resConvert[field] = res[field];
      delete res[field];
    }
  });
  const keys = Object.keys(res);

  if (keys.length === 1) {
    resConvert.data = res[keys[0]];
  }
  if (keys.length > 1) {
    isNil(res.data) ? (resConvert.data = res) : Object.assign(resConvert, res);
  }

  return resConvert;
}

const handlerCatchError = <TypeResponse>(err: AxiosError): Promise<IHttpResponse<TypeResponse>> => {
  if (err.response?.status === HttpStatusCode.Unauthorized) {
    redirectToLogin();
  }

  throw { code: get(err, 'error.code', err.response?.status), message: get(err, 'error.message', err.message), subMessage: get(err, 'error.errors', get(err, 'errors')) };
}

export default instanceAxios;
