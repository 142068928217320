import { useColorMode } from "@chakra-ui/system";
import { FileStatusColor, ThemeColor, Color } from "@/types/theme";
import { FileStatus } from "@/types/file";

const THEME = {
  statusCodeBg: {
    [FileStatus.PENDING]: ["gray.100", "gray.900"] as ThemeColor,
    [FileStatus.PROCESSING]: ["blue.100", "blue.900"] as ThemeColor,
    [FileStatus.DONE]: ["green.100", "green.900"] as ThemeColor,
    [FileStatus.NEED_TO_REVIEW]: ["orange.100", "orange.900"] as ThemeColor,
    [FileStatus.ERROR]: ["red.100", "red.900"] as ThemeColor
  } as FileStatusColor,
  statusCodeTextColor: {
    [FileStatus.PENDING]: ["gray.900", "gray.100"] as ThemeColor,
    [FileStatus.PROCESSING]: ["blue.900", "blue.100"] as ThemeColor,
    [FileStatus.DONE]: ["green.900", "green.100"] as ThemeColor,
    [FileStatus.NEED_TO_REVIEW]: ["orange.900", "orange.100"] as ThemeColor,
    [FileStatus.ERROR]: ["red.900", "red.100"] as ThemeColor
  } as FileStatusColor,
  actionIconColor: ["gray.300", "gray.500"] as ThemeColor,
  actionDividerColor: ["gray.200", "gray.600"] as ThemeColor,
  settingIconColor: ["gray.400", "gray.500"] as ThemeColor,
  headerBg: ["gray.50", "gray.700"] as ThemeColor,
  rowBorderBottomColor: ["gray.200", "gray.600"] as ThemeColor,
  selectedTableRowBg: ["gray.100", "gray.600"] as ThemeColor,
  selectedItemOptionBarBg: ["white", "gray.700"] as ThemeColor,
  selectedItemOptionBarBorder: ["blackAlpha.200", "gray.500"] as ThemeColor,
  paginationTotalPageTextColor: ["gray.300", "gray.500"] as ThemeColor,
  groupTitleBg: ["white", "gray.700"] as ThemeColor,
  boxBorderColor: ["blackAlpha.200", "gray.600"] as ThemeColor,
  secondaryTextColor: ["blackAlpha.500", "gray.500"] as ThemeColor,
  firstLoginFormContainerBorder: ["#63b3ed", "#FFFFFF"] as ThemeColor,
  secondLoginFormContainerBoder: ["#f6ad55", "#E500C1"] as ThemeColor,
  authInputBg: ["white", "#1A202C"] as ThemeColor,
  firstFloatBoxBg: ["#eaf3fa", "#342C73"] as ThemeColor,
  secondFloatBoxBg: [
    "#fce6cc",
    "linear-gradient(224.47deg, rgba(123, 97, 255, 0.59) 13.62%, rgba(230, 0, 193, 0.59) 129.44%);"
  ] as ThemeColor,
  thirdFloatBoxBg: [
    "#d9ebf9",
    "linear-gradient(108.98deg, rgba(123, 97, 255, 0.7) 14.72%, rgba(33, 14, 130, 0.7) 90.73%);"
  ] as ThemeColor,
  fourthFloatBoxBg: ["#fdead2", "#C75759"] as ThemeColor,
  loginNormalTextColor: ["#171923", "white"] as ThemeColor,
  authInputBorder: ["#CBD5E0", "#4A5568"] as ThemeColor,
  authInputFocusBorder: ["#718096", "#2D3748"] as ThemeColor
};

interface CustomThemeValue {
  getStatusCodeBg: (value: FileStatus) => Color;
  getStatusCodeTextColor: (value: FileStatus) => Color;
  actionIconColor: Color;
  actionDividerColor: Color;
  settingIconColor: Color;
  headerBg: Color;
  rowBorderBottomColor: Color;
  selectedTableRowBg: Color;
  selectedItemOptionBarBg: Color;
  selectedItemOptionBarBorder: Color;
  paginationTotalPageTextColor: Color;
  groupTitleBg: Color;
  boxBorderColor: Color;
  secondaryTextColor: Color;
  firstLoginFormContainerBorder: Color;
  secondLoginFormContainerBoder: Color;
  authInputBg: Color;
  firstFloatBoxBg: Color;
  secondFloatBoxBg: Color;
  thirdFloatBoxBg: Color;
  fourthFloatBoxBg: Color;
  loginNormalTextColor: Color;
  authInputBorder: Color;
  authInputFocusBorder: Color;
}

const useCustomTheme = (): CustomThemeValue => {
  const { colorMode } = useColorMode();
  const colorIndex = Number(colorMode === "dark");
  return {
    getStatusCodeBg: (value: FileStatus) => THEME.statusCodeBg[value][colorIndex],
    getStatusCodeTextColor: (value: FileStatus) => THEME.statusCodeTextColor[value][colorIndex],
    actionIconColor: THEME.actionIconColor[colorIndex],
    actionDividerColor: THEME.actionDividerColor[colorIndex],
    settingIconColor: THEME.settingIconColor[colorIndex],
    headerBg: THEME.headerBg[colorIndex],
    rowBorderBottomColor: THEME.rowBorderBottomColor[colorIndex],
    selectedTableRowBg: THEME.selectedTableRowBg[colorIndex],
    selectedItemOptionBarBg: THEME.selectedItemOptionBarBg[colorIndex],
    selectedItemOptionBarBorder: THEME.selectedItemOptionBarBorder[colorIndex],
    paginationTotalPageTextColor: THEME.paginationTotalPageTextColor[colorIndex],
    groupTitleBg: THEME.groupTitleBg[colorIndex],
    boxBorderColor: THEME.boxBorderColor[colorIndex],
    secondaryTextColor: THEME.secondaryTextColor[colorIndex],
    firstLoginFormContainerBorder: THEME.firstLoginFormContainerBorder[colorIndex],
    secondLoginFormContainerBoder: THEME.secondLoginFormContainerBoder[colorIndex],
    authInputBg: THEME.authInputBg[colorIndex],
    firstFloatBoxBg: THEME.firstFloatBoxBg[colorIndex],
    secondFloatBoxBg: THEME.secondFloatBoxBg[colorIndex],
    thirdFloatBoxBg: THEME.thirdFloatBoxBg[colorIndex],
    fourthFloatBoxBg: THEME.fourthFloatBoxBg[colorIndex],
    loginNormalTextColor: THEME.loginNormalTextColor[colorIndex],
    authInputBorder: THEME.authInputBorder[colorIndex],
    authInputFocusBorder: THEME.authInputFocusBorder[colorIndex]
  };
};

export default useCustomTheme;
