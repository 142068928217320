import React, { useState, useEffect, useMemo, useCallback, FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import type { APIQuery } from "@/types/global";
import type { ModalInfo } from "@/types/modal";
import { useNotifier } from "@/hooks";

interface ModalParams extends Pick<ModalInfo, "title" | "text"> {
  theme: "danger" | "warning";
  successText?: React.ReactNode;
  failedText?: React.ReactNode;
  onCancel?: () => void;
  onYes: () => void;
  onSuccess?: () => void;
  onFailed?: () => void;
  reloadPage?: (params?: APIQuery) => void;
}
export interface ModalContextProps {
  modal: ModalInfo;
  setModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  modalFetching: boolean;
  setModalFetching: Dispatch<SetStateAction<boolean>>;
  showConfirmModal: (params: ModalParams) => void;
  showOkModal: (params: Pick<ModalParams, "title" | "text" | "theme">) => void;
}

const ModalContext = React.createContext<ModalContextProps>({} as ModalContextProps);

const ModalProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const toast = useNotifier();

  const defaultModalInfo = useMemo(
    () => ({
      title: "",
      text: "",

      yesText: t("COMMON.YES"),
      onYes: undefined,

      cancelText: t("COMMON.CANCEL"),
      onCancel: undefined,

      okText: t("COMMON.OK"),
      onOk: undefined
    }),
    [t]
  );

  const [modal, setModalInfoState] = useState<ModalInfo>(defaultModalInfo);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalFetching, setModalFetching] = useState<boolean>(false);

  useEffect(() => {
    if (showModal) {
      setModalFetching(false);
    }
  }, [showModal]);

  const setModal = useCallback(
    (value: any): void => {
      setModalInfoState({ ...defaultModalInfo, ...value });
      setShowModal(true);
    },
    [defaultModalInfo]
  );

  const showConfirmModal = useCallback(
    ({ title, text, theme, successText, failedText, onYes, onCancel, onSuccess, onFailed }: any) => {
      setModal({
        title,
        text,
        cancelText: t("COMMON.NO"),
        onCancel: () => {
          onCancel && onCancel();
          setShowModal(false);
        },
        onYes: () => {
          if (typeof onYes === "function") {
            const yesResult = onYes();
            if (yesResult instanceof Promise) {
              setModalFetching(true);
              yesResult
                .then(
                  () => {
                    toast({
                      status: "success",
                      description: successText
                    });
                    onSuccess && onSuccess();
                  },
                  () => {
                    toast({
                      status: "error",
                      description: failedText
                    });
                    onFailed && onFailed();
                  }
                )
                .finally(() => {
                  setShowModal(false);
                });
            } else {
              setShowModal(false);
            }
          }
        },
        yesTheme: theme
      });
    },
    [setModal, t, toast]
  );

  const showOkModal = useCallback(
    ({ title, text, theme }: any) => {
      setModal({
        title,
        text,
        onOk: () => setShowModal(false),
        okTheme: theme
      });
    },
    [setModal]
  );

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        showModal,
        setShowModal,
        modalFetching,
        setModalFetching,
        showConfirmModal,
        showOkModal
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => React.useContext(ModalContext);

export { useModal, ModalProvider };
