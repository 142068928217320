import { CancelTokenSource } from "axios";
import { APIQuery } from "./global";
export type FileType = "image/jpeg" | "application/json" | "application/pdf";

export type FileExtension = ".pdf" | ".jpge" | ".jpg";

export interface IOFile extends Omit<File, "id"> {
  category: string;
  created_at: Date;
  file_name: string;
  id: string;
  job_id: string;
  media_type: FileType;
  size: number;
  updated_at: Date;
  uri: string;
}

export interface File {
  id: string;
  created_at: Date | string;
  created_by: any;
  updated_at: Date | string;
  updated_by: string;
  // file_name: string;
  status: FileStatus;
  result_updated_at: Date | string;
  result_updated_by: string;
  started_at: Date | string;
  finished_at: Date | string;
  error_log?: { message?: string } | null;
  number: number;
  input_file: IOFile;
}

export interface UploadFile extends Pick<IOFile, "id" | "size">, Omit<File, "id"> {
  lastModifiedDate: Date;
  name: string;
  type: FileType;
  arrayBuffer(): Promise<ArrayBuffer>;
  slice(start?: number, end?: number, contentType?: string): Blob;
  stream(): ReadableStream;
  text(): Promise<string>;
  lastModified: number;
  webkitRelativePath: string;
  cancelSource?: CancelTokenSource;
}

export type UploadStatus = "failed" | "pending" | "uploading" | "success";

export enum FileStatus {
  PENDING = 1,
  PROCESSING = 2,
  NEED_TO_REVIEW = 3,
  DONE = 4,
  ERROR = 9
}
export interface FilterFile extends Partial<File> {
  "input_file.file_name": string;
}

export interface FileMeta {
  filename: string;
  extension: FileExtension;
}

export interface FileAPIQuery extends APIQuery {
  workflow?: string;
  file_name?: string;
  status?: FileStatus;
  filter?: Record<string, any>;
}
