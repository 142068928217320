import clsx from "classnames";

import LoadingIndicator from "@components/Loader/Loader";
import styles from "./LoaderFull.module.scss";

interface LoaderFullProp {
  className?: string;
}

const LoaderFull = ({ className }: LoaderFullProp) => (
  <div className={clsx(styles.container, className)}>
    <LoadingIndicator />
  </div>
);

export default LoaderFull;
