import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const StyledCollapseButton = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #4299e1;
  font-weight: 700;
  cursor: pointer;
`;
