import { API_PATH } from "constants/config";
import { APIQuery, WorkflowAPIQuery } from "types/global";
import { request } from "helper/http";

import { Workflow } from "@/types/workflow";

export async function getWorkflows(params?: APIQuery) {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/`,
    method: "get",
    params: {
      limit: params?.limit,
      offset: params?.offset,
      search: params?.search
    }
  });
}

export const getAllWorkflows = async () => {
  let workflows: Workflow[] = [];
  const LIMIT = 30;

  // function will loop to get all workflows
  const getWorkflowsRecursive = async (offset: number, hasMore: boolean) => {
    if (!hasMore) {
      return;
    }
    const params: APIQuery = { limit: LIMIT, offset };
    const response = await getWorkflows(params);
    hasMore = response.results.length + workflows.length < response.count;
    offset += LIMIT;
    workflows = [...workflows, ...response.results];
    await getWorkflowsRecursive(offset, hasMore);
  };

  // execute to get all team
  await getWorkflowsRecursive(0, true);
  workflows?.sort((a, b) => a.display_name.localeCompare(b.display_name));
  return workflows ?? [];
};

export async function getWorkflowByCodeName(codeName: string) {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/?code_name=${codeName}`,
    method: "get"
  });
}

export async function getWorkflowByID(id: string) {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/${id}`,
    method: "get"
  });
}

export async function createWorkflow(payload?: any) {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/`,
    method: "post",
    data: payload
  });
}

export async function deleteWorkflow(id: string) {
  return request({
    url: API_PATH.WORKFLOW(id),
    method: "delete"
  });
}

export async function updateWorkflow({ id, part, payload }: { id: string; part: string; payload: WorkflowAPIQuery }) {
  return request({
    url: API_PATH.WORKFLOW(`${id}/${part}`),
    method: "patch",
    data: { ...payload }
  });
}
