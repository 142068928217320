import { HOST_ADM } from "@/constants/host-domain";
import { requestService } from "@/helper/http";
import { IAccount } from "./account.interface";

export const getAccount = async () => {
  return requestService<IAccount>({
    url: `${HOST_ADM()}my-account`,
    method: "get",
    params: {}
  });
}