import styled from "@emotion/styled";
import { Box, Text } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";

export const Stepper = styled(chakra.li)`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  // Horizontal Line
  &::before {
    content: "";
    margin-left: -35px;
    justify-content: center;
    align-items: center;
    color: #cbd5e0;
    text-align: center;
    display: flex;
    flex: ${(props: any) => `0 0 ${props.circlesize}px`};
    height: 2px;
    background-color: transparent;
  }

  // Vertical Line ( but not render Last Line)
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0px;
    z-index: -1;
    transform: ${(props: any) => `translateX(calc(${props.circlesize / 2 - 1}px ))`};
    width: 2px;
    background-color: transparent;
  }
`;

export const StepperContainer = styled(Box)`
  display: inline-block;
  min-width: 300px;
`;

export const StepperContent = styled(Box)`
  max-width: 180px;
  cursor: pointer;
`;

export const StepperTitle = styled(Text)`
  color: ${(props: any) => `${props.isactive ? "#4299E1" : "#718096"}`};
  font-size: 14px;
  font-weight: ${(props: any) => `${props.isactive ? 700 : 400}`};
  min-width: 170px;
  padding: 5px 12px;
  border-radius: 4px;
  background-color: ${(props: any) => `${props.isactive ? "#EBF8FF" : ""}`};
`;
export const StepperSubTitle = styled(Box)`
  margin-left: 20px;
  color: #718096;
  font-size: 14px;
  font-weight: 400;
`;
