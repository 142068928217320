import { HStack, Box, PopoverContent } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const StyledBox = styled(HStack)`
  padding: 0.5rem 1rem;
  align-items: center;
  min-width: 16rem;
  display: inline-flex;
  justify-content: space-between;
  border: 2px solid #cbd5e0;
  border-radius: 2px;
  background: ${(props: any) => ` ${props.isSelected ? "#ebf8ff" : "transparent"}`};
  border: ${(props: any) => `2px solid ${props.isSelected ? "#4299e1" : "#cbd5e0"}`};
  :focus {
    border: 2px solid #4299e1;
    background: #ebf8ff;
  }
`;

export const StyledIconBox = styled(Box)`
  background: #ebf8ff;
  border-radius: 2px;
  padding: 0.5rem;
`;

export const StyledName = styled(Box)`
  font-weight: bold;
  margin-left: 1rem;
  font-size: 0.875rem;
`;

export const StyledPopOverContent = styled(PopoverContent)`
  :focus {
    box-shadow: none !important;
    outline: none !important;
  }
  border: 1px solid #cbd5e0 !important;
`;
