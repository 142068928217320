import React from "react";
import clsx from "classnames";

import styles from "./Loader.module.scss";

interface LoadingIndicatorProps {
  className?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ className, ...props }) => (
  <span className={clsx(styles.container, className)} {...props} />
);

export default LoadingIndicator;
