import styled from "@emotion/styled";
import { Box, Text } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";

export const Stepper = styled(chakra.li)`
  position: relative;
  display: flex;
  gap: 1rem;
  padding-bottom: 4rem;

  // Circle
  &::before {
    content: ${(props: any) => `"${props.step}"`};
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    display: flex;
    flex: ${(props: any) => `0 0 ${props.circlesize}px`};
    height: ${(props: any) => `${props.circlesize}px`};
    border-radius: 50%;
    background-color: ${(props: any) => `${props.isactive ? "#4299e1" : "#CBD5E0"}`};
    font-size: 14px;
    font-weight: 700;
  }

  // Vertical Line ( but not render Last Line)
  :not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      bottom: 0px;
      z-index: -1;
      transform: ${(props: any) => `translateX(calc(${props.circlesize / 2 - 1}px ))`};
      width: 2px;
      background-color: ${(props: any) => `${props.isactiveline ? "#4299e1" : "#cbd5e0"}`};
      -webkit-transition: all linear 0.8s;
      -moz-transition: all linear 0.8s;
      transition: all linear 0.8s;
      /* box-shadow: ${(props: any) =>
        `inset 0 ${props.isactiveline ? 1000 : 0}px 0 0 #4299e1`}; // boxShadow will full 1000 */
    }
  }
`;

export const StepperContainer = styled(Box)`
  display: inline-block;
  min-width: 300px;
`;

export const StepperContent = styled(Box)`
  max-width: 160px;
`;

export const StepperTitle = styled(Text)`
  color: ${(props: any) => `${props.isactive ? "#4299E1" : "#171923"}`};
  font-size: 14px;
  font-weight: ${(props: any) => `${props.isactive ? 700 : 400}`}; ;
`;
export const StepperDescription = styled(Text)`
  color: #718096;
  font-size: 14px;
  font-weight: 400;
`;
