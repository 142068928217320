import { Button, Link as ChakraLink, Tab } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

export const StyledLink = styled(ChakraLink)`
  &:hover {
    text-decoration: none;
  }
  &:focus {
    box-shadow: none;
  }
`;

export const StyledNavLink = styled(NavLink)`
  padding: 0.5rem 0 0.5rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  max-width: 15rem;
  &:hover {
    text-decoration: none;
    color: #4299e1;
  }
`;

export const StyledButton = styled(Button)`
  background: #4299e1;
  font-size: 0.875rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const StyledTab = styled(Tab)`
  font-size: 0.875rem;
  font-weight: bold;
  color: #171923;
  border-bottom: none;
  min-width: 150px;
  border-radius: 0.25rem;

  &:focus {
    box-shadow: none;
  }

  &:active:focus {
    background-color: #e2e8f0;
  }

  &[aria-selected="true"] {
    font-weight: bold;
    background-color: #4299e1;
    color: #fff;
    border-bottom: none;
  }
`;
