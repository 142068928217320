import { IDetailPatientAllForm, IParamsGetDetail } from "@/services/objects/patient/patient.interface";
import { detailPastPatient } from "@/services/objects/patient/patient.service";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface InitialDetailPatientState {
  isLoading: boolean,
  errorMessage: string;
  detailOfPatient: IDetailPatientAllForm | undefined;
  query: IParamsGetDetail;
};

export const detailPatientAsyncThunk = createAsyncThunk(
  "patient/detail", async (params: IParamsGetDetail, { rejectWithValue }) => {
    try {
      const result = await detailPastPatient(params);
      if (!result) {
        return {}
      }

      return result;
    } catch (err) {
      return rejectWithValue(JSON.stringify(err as any));
    }
  }
);

export const detailPatientSlice = createSlice({
  name: 'detailPatients',

  // Thêm 1 số state như trạng thái loading, báo lỗi và thông tin user đang đăng nhập
  initialState: {
    isLoading: false,
    errorMessage: '',
    detailOfPatient: undefined,
  } as InitialDetailPatientState,

  // Các action bình thường (sync action)
  reducers: {
    updateFilter: (state, { payload }: PayloadAction<IParamsGetDetail>) => {
      state.query = payload;
    },
    resetDetailPatient: (state) => {
      state.detailOfPatient = undefined;
    },
  },

  // Code logic xử lý async action
  extraReducers: (builder) => {
    // Bắt đầu thực hiện action login (Promise pending)
    builder.addCase(detailPatientAsyncThunk.pending, (state) => {
      // Bật trạng thái loading
      state.isLoading = true;
    });

    // Khi thực hiện action login thành công (Promise fulfilled)
    builder.addCase(detailPatientAsyncThunk.fulfilled, (state, action) => {
      // Tắt trạng thái loading, lưu thông tin user vào store
      state.isLoading = false;
      state.detailOfPatient = action.payload.data as IDetailPatientAllForm;
    });

    // Khi thực hiện action login thất bại (Promise rejected)
    builder.addCase(detailPatientAsyncThunk.rejected, (state, action) => {
      // Tắt trạng thái loading, lưu thông báo lỗi vào store
      state.isLoading = false;
      // state.errorMessage = action.payload.message;
    });
  },
});

export const { updateFilter, resetDetailPatient } = detailPatientSlice.actions;

export default detailPatientSlice.reducer;
