import { FORM_STEP_ID_PREFIX } from "@/constants/global";
import { FormSteps } from "@/types/global";
import { useEffect } from "react";

const getFormStepId = (step: FormSteps) => `${FORM_STEP_ID_PREFIX}${step}`;

const useIntersectionObserver = (steps: FormSteps[], callback: (nextStep: FormSteps) => void) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            const formId = entry.target.getAttribute("id")!;
            const nextStep = parseInt(formId.split("-")[1], 10);
            callback(nextStep);
          }
        }
      },
      { threshold: 0, root: null, rootMargin: `-50% 0px -50% 0px` }
    );

    steps.forEach((step) => {
      const formEl = document.getElementById(getFormStepId(step));
      formEl && observer.observe(formEl);
    });

    return () => observer.disconnect();
  }, []);
};

export default useIntersectionObserver;
