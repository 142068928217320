/* eslint-disable */
import { clearSession } from "@/redux/reducer/userSlice";
import store from "@/redux/store";
import { TFunction } from "i18next";
import { nanoid } from "nanoid";
import type { LocalStorageType, QueryOperator } from "types/global";
import type { Color } from "types/theme";
import { APP_CONFIG } from "../constants/config";
import {
  DATE_FORMAT,
  GLOBAL_EVENTS,
  MONTH_SHORT,
  QUERY_OPERATOR,
  REFRESH_TOKEN_STORAGE_NAME,
  TOKEN_STORAGE_NAME,
} from "../constants/global";
import { CommonCacheService } from "@/services/common/cache.service";

// DEFINE
export const SIZE_UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export const getDateString = (dateObj: Date, format = APP_CONFIG.DATE_FORMAT, t?: TFunction): string => {
  const date = `0${dateObj.getDate()}`.slice(-2);
  const month = `0${dateObj.getMonth() + 1}`.slice(-2);
  const year = dateObj.getFullYear();
  const hours = `0${dateObj.getHours()}`.slice(-2);
  const mins = `0${dateObj.getMinutes()}`.slice(-2);
  const secs = `0${dateObj.getSeconds()}`.slice(-2);

  switch (format) {
    // 2001 Jan
    case DATE_FORMAT.YYYY_MMM:
      return t
        ? `${t("DATE.YEAR", { year })} ${t(MONTH_SHORT[dateObj.getMonth() + 1])}`
        : `${year} ${MONTH_SHORT[dateObj.getMonth() + 1]}`;
    // Jan, 27 2001
    case DATE_FORMAT.MMM_DD_YYYY:
      return `${t ? t(MONTH_SHORT[dateObj.getMonth() + 1]) : MONTH_SHORT[dateObj.getMonth() + 1]}, ${date} ${year}`;
    // 2001/01/27
    case DATE_FORMAT.YYYY_MM_DD_SLASH:
      return `${year}/${month}/${date}`;

    // 2001/01/27 17:34
    case DATE_FORMAT.YYYY_MM_DD_HH_MM_SLASH:
      return `${year}/${month}/${date} ${hours}:${mins}`;

    // 2001/01/27 17:34:12
    case DATE_FORMAT.YYYY_MM_DD_HH_MM_SS_SLASH:
      return `${year}/${month}/${date} ${hours}:${mins}:${secs}`;

    // 2001.01.27
    case DATE_FORMAT.YYYY_MM_DD_DOT:
      return `${year}.${month}.${date}`;

    // 2001.01.27 17:34
    case DATE_FORMAT.YYYY_MM_DD_HH_MM_DOT:
      return `${year}.${month}.${date} ${hours}:${mins}`;

    // 2001.01.27 17:34:12
    case DATE_FORMAT.YYYY_MM_DD_HH_MM_SS_DOT:
      return `${year}.${month}.${date} ${hours}:${mins}:${secs}`;

    case DATE_FORMAT.DD_MM_YYYY_SLASH:
      return `${date}/${month}/${year}`;

    case DATE_FORMAT.YYYYMMDDhhmmss:
      return `${year}${month}${date}${hours}${mins}${secs}`;
    default:
      return dateObj.toString();
  }
};

export const getFileSizeWithUnit = (inputBytes: number, fractionDigits = 2): string => {
  const bytes = inputBytes;
  let root = 0;

  while (bytes / Math.pow(1024, root + 1) > 1) {
    root++;
  }

  const formatedSize = (bytes / Math.pow(1024, root)).toFixed(fractionDigits);
  return `${formatedSize} ${SIZE_UNITS[root]}`;
};

export const isEmpty = (input: string | boolean | Record<string, any>): boolean => {
  if (
    input === "" ||
    input === null ||
    input === undefined ||
    (typeof input === "object" && Object.keys(input).length <= 0)
  ) {
    return true;
  }
  return false;
};

export const limitNumberInRange = (number: number, min: number, max: number): number => {
  if (min > max) return number;

  const minValue = min || number;
  const maxValue = max || number;

  return Math.max(Math.min(number, maxValue), minValue);
};

export const extractFileName = (fileName: string = ""): { name: string; ext: string } => {
  let name = "";
  let ext = "";
  const dotIndex = fileName.lastIndexOf(".");

  if (dotIndex > 0) {
    name = fileName.substring(0, dotIndex);
    ext = fileName.substring(dotIndex + 1, fileName.length);
  } else {
    name = fileName;
  }

  return {
    name,
    ext: (ext || "").toLowerCase(),
  };
};

export const getFilterObject = (value: QueryOperator, operator = QUERY_OPERATOR.EQUAL): object => {
  let operatorObj: Record<string, QueryOperator> = {};
  if (typeof value === "string") {
    operatorObj[operator] = value;
  } else if (typeof value === "object") {
    operatorObj = value;
  }
  return operatorObj;
};

export const getFileExtension = (filename: string) => extractFileName(filename).ext;

export const createTempAnchorAndDownload = (href: string, fileName: string) => {
  const aTag = document.createElement("a");
  aTag.href = href;
  aTag.target = "_blank";
  if (fileName) {
    aTag.download = fileName;
  }
  document.body.appendChild(aTag);
  aTag.click();
  document.body.removeChild(aTag);
};

export const getFileNameFromContentDisposition = (conDis: `attachment ; ${string}`) => {
  let fileName = "";
  if (conDis && conDis.indexOf("attachment") !== -1) {
    const matches = /filename[^;=\n]*=(?:.*'')?((['"]).*?\2|[^;\n]*)/.exec(conDis);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }

  return decodeURIComponent(fileName);
};

export const getFileNameWithoutExtension = (name: string) => {
  if (!name) {
    return APP_CONFIG.DEFAULT_DOWNLOAD_FILE_NAME;
  }

  const index = name.lastIndexOf(".");
  if (index === -1) {
    return APP_CONFIG.DEFAULT_DOWNLOAD_FILE_NAME;
  }

  return name.slice(0, index);
};

export const downloadFromBinary = (fileBinary: BlobPart, fileName: string): void => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(new Blob([fileBinary]));
  link.target = "_blank";
  link.download = fileName;
  link.click();
};

/**
 * Save login data into LocalStorage
 */

export const saveLoginInfo = (data: LocalStorageType): void => {
  localStorage.setItem(APP_CONFIG.LS_AUTH_DATA, JSON.stringify(data));
};

/**
 * Clear login data stored in LocalStorage
 */
export const clearLoginInfo = (): void => {
  localStorage.removeItem(TOKEN_STORAGE_NAME);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
  localStorage.removeItem(APP_CONFIG.LS_AUTH_DATA);
  window.dispatchEvent(new Event(GLOBAL_EVENTS.LOGOUT));

  store.dispatch(clearSession());
};

/**
 * Get login data from LocalStorage
 */
export const getLoginInfo = (): LocalStorageType => JSON.parse(localStorage.getItem(APP_CONFIG.LS_AUTH_DATA)!);

export const removeEmptyFields = (object: Record<string, any>): object => {
  const result = { ...object };
  for (const [key, value] of Object.entries(object)) {
    if (isEmpty(value)) {
      delete result[key];
    }
  }
  return result;
};

/** NOT USED **/
// export const sortObjectArrayByField = (
// 	array: Array<Record<string, any>>,
// 	field: string,
// 	order: Sorting = 'asc'
// ): Array<Record<string, any>> => {
// 	const result = [...array];
// 	const orderMultiplier = order === 'asc' ? 1 : -1;
// 	result.sort((a, b) => {
// 		if (!a[field] && b[field]) return 1 * orderMultiplier;
// 		if (a[field] && !b[field]) return -1 * orderMultiplier;
// 		if (!a[field] && !b[field]) return 0;

// 		const fieldOfA = a[field].toUpperCase();
// 		const fieldOfB = b[field].toUpperCase();

// 		return fieldOfA < fieldOfB
// 			? -1 * orderMultiplier
// 			: fieldOfA > fieldOfB
// 			? 1 * orderMultiplier
// 			: 0;
// 	});
// 	return result;
// };
export function roundScore(score: number) {
  const remainder = score % 5;
  if (remainder === 0) return score;
  return remainder >= 3 ? score + (5 - remainder) : score - remainder;
}

export const sortPropertiesInObject = (obj: Record<string, any>): object => {
  const properties = Object.keys(obj).map((key: string | number) => [key, obj[key]]);
  properties.sort((a, b) => a[0].localeCompare(b[0]));

  const sortedObj: Record<string, any> = {};
  properties.forEach((item) => {
    sortedObj[item[0]] = item[1];
  });
  return sortedObj;
};

export const isDiff = (obj1?: object, obj2?: object): boolean => {
  if (obj1 !== null && obj2 !== null && typeof obj1 === "object" && typeof obj2 === "object") {
    // Sort properties first
    const sorted1 = sortPropertiesInObject(obj1);
    const sorted2 = sortPropertiesInObject(obj2);

    return JSON.stringify(sorted1) !== JSON.stringify(sorted2);
  }
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

/** NOT USED **/
// export const getChangedFields = (
// 	obj1: Record<string, any>,
// 	obj2 = {} as Record<string, any>
// ): object => {
// 	const result: Record<string, any> = {};
// 	if (obj1 !== null && obj2 !== null && typeof obj1 === 'object' && typeof obj2 === 'object') {
// 		for (const key in obj2) {
// 			if (isDiff(obj1[key], obj2[key])) {
// 				result[key] = obj2[key];
// 			}
// 		}
// 	}
// 	return result;
// };

export const urlSearchToObject = (urlSearch: string): object => {
  const searchObj = new URLSearchParams(urlSearch);
  const result: Record<string, any> = {};
  searchObj.forEach((key, value) => (result[key] = value));
  return result;
};

/** NOT USED **/
// export const filterObjToSearch = (filterObj: Record<string, any>): string => {
// 	let result = '';
// 	for (const key in filterObj) {
// 		result += result ? ` ${key}=${filterObj[key]}` : `${key}=${filterObj[key]}`;
// 	}
// 	return result;
// };

export const includeStr = (str1: string = "", str2: string = ""): boolean =>
  str1.toLowerCase().includes(str2.toLowerCase());

export const removeElement = (
  array: unknown[],
  conditionFn: (initValue: unknown, index: number) => boolean
): unknown[] => {
  const result = [...array];
  for (let index = 0; index < array.length; index++) {
    if (conditionFn(array[index], index)) {
      result.splice(index, 1);
      break;
    }
  }
  return result;
};

export const payloadToFormData = (payload: any) => {
  const formData = new FormData();
  for (const key in payload) {
    formData.append(key, payload[key]);
  }

  return formData;
};

export const getRouteSearchStr = (queryObj: { sort: string }): string => `?${new URLSearchParams(queryObj).toString()}`;

export const makeId = (): string => nanoid();

export const removeKeys = (obj: any, keys: Array<keyof any>): object => {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result ?? {};
};

export const convertingColorNameToVar = (colorName: Color) =>
  `var(--chakra-colors-${(colorName as string)?.replace(".", "-")})`;

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const stringToColor = (s: string) => {
  const hash = s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
  const num = hash / Math.pow(10, s.length);
  return "hsl(" + num * 360 + ", 100%, 75%)";
};

export const hasRole = (scope: string, role?: string) => {
  if (!role) return true;
  return scope.includes(role);
};

export const redirectToLogin = () => {
  CommonCacheService.ClearAll();
  window.dispatchEvent(new Event(GLOBAL_EVENTS.LOGOUT));
  store.dispatch(clearSession());
  location.href = `/login`;
}