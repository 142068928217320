import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "redux/reducer/userSlice";
import patientReducer from "redux/reducer/patientSlice";
import detailPatientReducer from "redux/reducer/detailPatientSlice";
import workflowReducer from "redux/reducer/workflowSlice";
import layoutReducer from "redux/reducer/layoutReducer";
import formScoreReducer from "redux/reducer/formScoreSlice";
import toolsReducer from "redux/reducer/toolSlice";

export const rootReducer = combineReducers({
  user: userReducer,
  workflow: workflowReducer,
  layout: layoutReducer,
  formScore: formScoreReducer,
  tools: toolsReducer,
  patients: patientReducer,
  detailPatients: detailPatientReducer,
});
