import { DATE_FORMAT, LANGUAGE_CODE } from "./global";

export const APP_CONFIG = {
  NAME: process.env.REACT_APP_NAME,
  LS_AUTH_DATA: `${process.env.REACT_APP_NAME}_auth_data`,
  LS_LANGUAGE: `language`,
  API_ROOT: `${process.env.REACT_APP_API_DOMAIN}/api/v1`,
  DEFAULT_LANGUAGE: LANGUAGE_CODE.ENGLISH,
  UPLOAD_FILE_SUPPORT: [".pdf"],
  UPLOAD_QUEUE_LENGTH: 3,
  UPLOAD_MAX_SIZE: 52428800, // 50 MB
  DEFAULT_DOWNLOAD_FILE_NAME: "download",
  DEFAULT_DOWNLOAD_FILES_NAME: "downloads",
  DATE_FORMAT: DATE_FORMAT.YYYY_MM_DD_HH_MM_SLASH,
  USER: {
    PASSWORD_MIN_LENGTH: 5,
  },
};

export const API_PATH = {
  LOGIN: `${APP_CONFIG.API_ROOT}/login/`,
  REFRESH: `${APP_CONFIG.API_ROOT}/refresh/`,
  USER_INFO: `${APP_CONFIG.API_ROOT}/user/me/`,
  WORKFLOWS_LIST: `${APP_CONFIG.API_ROOT}/workflows`,
  WORKFLOW: (id: string) => `${APP_CONFIG.API_ROOT}/workflows/${id}/`,
  JOBS: `${APP_CONFIG.API_ROOT}/jobs/`,
  BULK_DELETE_JOB: `${APP_CONFIG.API_ROOT}/jobs/bulk/`,
  JOB_DOWNLOAD: (id: string) => `${APP_CONFIG.API_ROOT}/jobs/${id}/result/download/`,
  JOBS_DOWNLOAD_BATCH: `${APP_CONFIG.API_ROOT}/jobs/result/bulk-download/`,
  USERS: `${APP_CONFIG.API_ROOT}/users/`,
  BULK_DELETE_USER: `${APP_CONFIG.API_ROOT}/users/bulk/`,
  CHANGE_PASSWORD: `${APP_CONFIG.API_ROOT}/users/me/change-password/`,
  JOB_DATA: (id: string, showResult: boolean = true) => `${APP_CONFIG.API_ROOT}/jobs/${id}/?show_result=${showResult}`,
  STATIC: (jobId: string, category: string, fileIndex: number = 0) =>
    `${APP_CONFIG.API_ROOT}/static/resources/${jobId}/${category}/${fileIndex}`,
  FILES_CONTENT: (fileId: string) => `${APP_CONFIG.API_ROOT}/files/${fileId}/content`,
  PIPELINES: `${APP_CONFIG.API_ROOT}/pipelines`,
  TEAMS: `${APP_CONFIG.API_ROOT}/teams/`,
  BULK_DELETE_TEAM: `${APP_CONFIG.API_ROOT}/teams/bulk/`,
  CHECK_EMAIL: `${APP_CONFIG.API_ROOT}/users/check-mail`,
  GROUPS: `${APP_CONFIG.API_ROOT}/groups/`,
  STATS: `${APP_CONFIG.API_ROOT}/stats`,
  PATIENT: `${APP_CONFIG.API_ROOT}/patients`,
};
