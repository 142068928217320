import React from "react";
import { chakra, Box, Input, useRadio, useRadioGroup } from "@chakra-ui/react";
import styled from "@emotion/styled";

type CustomRadioOptionProps = {
  content: JSX.Element;
  value: string;
};

type CustomRadioProps = {
  onChangeOption: (value: string) => void;
  options: CustomRadioOptionProps[];
  radioGroupName: string;
  defaultOptionValue: string;
};

export const RadioContainer = styled(chakra.label)`
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ .checkmark {
    background-color: #2196f3;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }
`;

export const RadioCheckmark = styled(chakra.span)`
  position: absolute;
  margin-top: 3px;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #a0aec0;
  background-color: white;
  border-radius: 50%;
  :after {
    content: "";
    position: absolute;
    display: none;
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

const RadioItem = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <RadioContainer fontSize="14px" as="label">
      {props.content}
      <Input type="radio" {...input} />
      <RadioCheckmark className="checkmark" {...checkbox}></RadioCheckmark>
    </RadioContainer>
  );
};

const CustomRadio: React.FC<CustomRadioProps> = ({ onChangeOption, options, radioGroupName, defaultOptionValue }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: radioGroupName,
    defaultValue: defaultOptionValue,
    onChange: onChangeOption,
  });

  const group = getRootProps();
  return (
    <Box {...group}>
      {options.map((item: CustomRadioOptionProps, index: number) => {
        const radio = getRadioProps({ value: item.value });
        return (
          <RadioItem key={`${item.value}-${index}`} content={item.content} {...radio}>
            {item.value}
          </RadioItem>
        );
      })}
    </Box>
  );
};

export default CustomRadio;
