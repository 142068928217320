import { IHttpResponse } from "@/types/http-response.interface";
import { DefinePattern } from "@/utils/pattern";
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { HTTP_CODE } from "constants/global";
import { clearLoginInfo, redirectToLogin } from "helper/utility";
import { get } from "lodash";
import instanceAxios, { getOptions } from "./axios";

function transformError(error: AxiosError) {
  const errorObj: any = { ...error.response };
  errorObj.status = error.response?.status;
  errorObj.errors = (error.response?.data as any)?.errors;
  errorObj.message = (error.response?.data as any)?.message || error.message;
  return errorObj;
}

function handleError(error: any) {
  const _error = transformError(error);
  if (_error.status === HTTP_CODE.UNAUTHORIZED) {
    clearLoginInfo();
  }
  throw _error;
}

export type RequestOptions = AxiosRequestConfig & {
  shouldGetFullResponse?: boolean;
  useTimeStamp?: boolean;
  onUploadProgress?: (progressEvent: any) => void;
  convertResponseData?: (res: any) => any
};

export async function request({
  shouldGetFullResponse = false,
  useTimeStamp = false,
  ...configs
}: RequestOptions = {}) {
  const axiosConfigs: AxiosRequestConfig = { ...configs };
  if (useTimeStamp) {
    axiosConfigs.params = {
      ...axiosConfigs.params,
      timestamp: Date.now()
    };
  }

  try {
    const result = await instanceAxios.request(axiosConfigs);
    if (shouldGetFullResponse) {
      return result;
    }
    return result.data;
  } catch (error) {
    return handleError(error);
  }
}


export async function requestService<TypeResponse>({ ...configs }: RequestOptions = {}) {
  const axiosConfigs: AxiosRequestConfig = { ...configs };

  const optimizeUrl = replaceURLByPattern(configs.url || '', configs.params);

  configs.url = optimizeUrl.url;
  configs.params = optimizeUrl.params;

  try {
    const result = await instanceAxios.request(axiosConfigs);

    if (configs.convertResponseData) {
      return configs.convertResponseData(result.data) as IHttpResponse<TypeResponse>;
    }

    return result.data as IHttpResponse<TypeResponse>;
  } catch (error) {
    console.log('err service', error);

    handlerError(error);
  }
}

export async function requestFormDataService<TypeResponse>({ ...configs }: RequestOptions = {}) {
  const formData = new FormData();
  const axiosConfigs: AxiosRequestConfig = { ...configs };
  const bodyData = axiosConfigs.data;

  axiosConfigs.headers = getOptions('multipart/form-data').headers as AxiosRequestHeaders;

  for (const property in axiosConfigs.data) {
    appendRecursive(formData, bodyData[property], property);
  }

  try {
    const result = await axios.request(axiosConfigs);

    return result.data as IHttpResponse<TypeResponse>;
  } catch (error) {
    handlerError(error);
  }
}

function handlerError(error: any) {
  const err = transformError(error);
  if (err.status === HTTP_CODE.UNAUTHORIZED) {
    redirectToLogin();
  }

  throw { code: get(err, 'error.code', err.status), message: get(err, 'error.message', err.message), subMessage: get(err, 'error.errors', get(err, 'errors')) };
}


function appendRecursive<TypeBody>(fData: FormData, data: TypeBody, prop: string, keepFileOfBody?: boolean, keepArrayValue?: boolean) {
  if (keepFileOfBody) {
    if (data instanceof File || Object.prototype.toString.call(data) === '[object File]') {
      appendFileToFormData(fData, prop, data as File);

      return;
    }
    if (data instanceof Array && data.length && data[0] instanceof File) {
      for (const file of data) {
        appendFileToFormData(fData, prop, file);
      }

      return;
    }
  }
  if (keepArrayValue && data instanceof Array) {
    fData.append(prop, JSON.stringify(data));

    return;
  }
  if ('object' === typeof data) {
    fData.append(prop, JSON.stringify(data));

    return;
  }
  fData.append(prop, data as any);
}

function appendFileToFormData(formData: FormData, prop: string, file: File) {
  formData.append(prop, file, file.name);
}


const replaceURLByPattern = (url: string, params?: { [key: string]: any }): { url: string, params: { [key: string]: any } } => {
  if (!params) {
    return { url, params: {} };
  }
  const urls = url.split('?');
  const tempUrls = [...urls];
  const queries: Array<string> = [];

  url = tempUrls.shift() || '';
  if (tempUrls.length) {
    queries.push(...tempUrls.join('&').split('&'));
  }

  queries.forEach((queryStr: string) => {
    const paramsArg = queryStr.split('=');

    if (paramsArg.length === 2) {
      params[paramsArg[0]] = paramsArg[1];
    }
  });

  const ruleFields = url.match(DefinePattern.RULE_FIELD_REPLACE);

  if (!ruleFields || !ruleFields.length) {
    return { url, params };
  }
  ruleFields.forEach((ruleField: string): void => {
    const field = ruleField.match(DefinePattern.GET_FIELD_BY_RULE_FIELD_REPLACE)?.shift();

    if (!field) {
      return;
    }

    const valueReplace = params?.[field];

    if (!valueReplace) {
      return;
    }
    url = url.replace(ruleField, valueReplace);
    delete params[field];
  });

  return { url, params };
}

// export async function getWithPaging({
//   method = "get",
//   params = {},
//   shouldGetFullResponse = false,
//   useTimeStamp = false,
//   ...configs
// }: RequestOptions) {
//   const { sort, skip, limit, filter } = params;

//   const sQuery = removeEmptyFields({
//     // Pagination
//     sort,
//     limit,
//     skip,
//     // Filters
//     ...(isEmpty(filter) ? {} : { filter: JSON.stringify(filter) })
//   });

//   const result = await request({
//     method,
//     params: sQuery,
//     shouldGetFullResponse,
//     useTimeStamp,
//     ...configs
//   });
//   if (shouldGetFullResponse) return result;
//   return {
//     meta: { skip, limit, ...result.meta },
//     items: result.items
//   };
// }
