import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";
import styled from "@emotion/styled";

const isOutlineBtn = (props: ButtonProps) => {
  if (props.variant === "outline" && props.colorScheme === "blue") {
    return true;
  }
  return false;
};

const isExtraBlue = (props: ButtonProps) => {
  if (props.colorScheme === "extraBlue.400" && !props.variant) {
    return true;
  }
  return false;
};

const Button = styled(ChakraButton)`
  border: ${(props) => (isOutlineBtn(props) ? "2px solid #4299E1" : props.border)};
  font-size: 0.875rem;
  font-weight: bold;
  color: ${(props) => (isOutlineBtn(props) ? "#4299E1" : props.color)};
  padding: 1rem 1.5rem;
  border-radius: 6px;
  &:hover {
    background-color: ${(props) => {
      return isExtraBlue(props) ? "#3182CE" : props._hover?.background;
    }};
  }
`;
export default Button;
