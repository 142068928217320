import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FormState {
  KATZ_SCORE: KATZ_SCORE;
  impellaScore: number;
  syntaxFavors: number;
  ECABG_SCORE: number;
  bleedingRiskScore: string;
  mortalityScore: string;
  riskEmergency: number;
  riskCategory: string;
  unit: string;
  GFR_score: number;
  CIN_score: number;
}

export interface KATZ_SCORE {
  bathing: number;
  dressing: number;
  toileting: number;
  feeding: number;
  transferring: number;
  continence: number;
}

const initialState: FormState = {
  KATZ_SCORE: {
    bathing: 0,
    dressing: 0,
    toileting: 0,
    feeding: 0,
    transferring: 0,
    continence: 0,
  },
  ECABG_SCORE: 0,
  impellaScore: 0,
  syntaxFavors: 0,
  GFR_score: 0,
  CIN_score: 0,
  bleedingRiskScore: "",
  mortalityScore: "",
  riskEmergency: 0,
  riskCategory: "",
  unit: "",
};

export const formScoreSlice = createSlice({
  name: "formScore",
  initialState,
  reducers: {
    set_KATZ_Score: (state, { payload }: PayloadAction<{ score: number; name: keyof KATZ_SCORE }>) => {
      const { score, name } = payload;
      state.KATZ_SCORE[name] = score;
    },
    setBleedingScore: (state, { payload }: PayloadAction<{ score: string }>) => {
      const { score } = payload;
      state.bleedingRiskScore = score;
    },

    setMortalityScore: (state, { payload }: PayloadAction<{ score: string }>) => {
      const { score } = payload;
      state.mortalityScore = score;
    },
    setRiskEmergency: (state, { payload }: PayloadAction<{ score: number }>) => {
      const { score } = payload;
      state.riskEmergency = score;
    },

    setRiskCategory: (state, { payload }: PayloadAction<{ text: string }>) => {
      const { text } = payload;
      state.riskCategory = text;
    },
    set_GFR_score: (state, { payload }: PayloadAction<{ score: number }>) => {
      const { score } = payload;
      state.GFR_score = score;
    },
    set_CIN_score: (state, { payload }: PayloadAction<{ score: number }>) => {
      const { score } = payload;
      state.CIN_score = score;
    },
    increaseECABG_score: (state, { payload }: PayloadAction<{ score: number }>) => {
      const { score } = payload;
      state.ECABG_SCORE = score;
    },
    decreaseECABG_score: (state, { payload }: PayloadAction<{ score: number }>) => {
      const { score } = payload;
      if (state.ECABG_SCORE === 0) return;
      state.ECABG_SCORE -= score;
    },
    increaseImpellaScore: (state, { payload }: PayloadAction<{ value: number; label: string }>) => {
      const { value } = payload;

      state.impellaScore += value;
    },
    decreaseImpellaScore: (state, { payload }: PayloadAction<{ value: number; label: string }>) => {
      const { value } = payload;
      if (state.impellaScore === 0) return;
      state.impellaScore -= value;
    },

    setUnit: (state, { payload }: PayloadAction<{ value: string }>) => {
      const { value } = payload;
      state.unit = value;
    },
  },
});

export const {
  setUnit,
  set_KATZ_Score,
  set_GFR_score,
  set_CIN_score,
  decreaseImpellaScore,
  increaseImpellaScore,
  increaseECABG_score,
  decreaseECABG_score,
  setRiskEmergency,
  setBleedingScore,
  setMortalityScore,
  setRiskCategory,
} = formScoreSlice.actions;

export default formScoreSlice.reducer;
