import { createSlice } from "@reduxjs/toolkit";

export interface ILayoutState {
  isMenuOpened: boolean;
}
const initialState: ILayoutState = {
  isMenuOpened: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleMenuOpen: (state) => {
      state.isMenuOpened = !state.isMenuOpened;
    },
  },
});

export const { toggleMenuOpen } = layoutSlice.actions;

export default layoutSlice.reducer;
