import { HOST_ADM } from "@/constants/host-domain";
import { requestFormDataService, requestService } from "@/helper/http";
import { IBodyCreatePatient, IDetailPatientAllForm, IParamsGetDetail, IParamsGetListPastPatients, IParamsGetListPatients, IPastPatient, IPatient } from "./patient.interface";

export const listPatient = async (params?: IParamsGetListPatients) => {
  return requestService<IPatient>({
    url: `${HOST_ADM()}patients`,
    method: "get",
    params: params
  });
}

export const createPatient = async (body: IBodyCreatePatient) => {
  return requestFormDataService<IPatient>({
    url: `${HOST_ADM()}patients`,
    method: "post",
    data: body
  });
}


export const listPastPatient = async (params?: IParamsGetListPastPatients) => {
  return requestService<Array<IPastPatient>>({
    url: `${HOST_ADM()}medical-history`,
    method: "get",
    params: params
  });
}


export const detailPastPatient = async (params: IParamsGetDetail) => {
  const history_id = params.history_id;

  delete params.history_id;

  return requestService<IDetailPatientAllForm>({
    url: `${HOST_ADM()}medical-detail/${history_id}`,
    method: "get",
    params: params,
    convertResponseData: (res) => {
      const data = res.data;

      if (data.follow_up?.length) {
        data.follow_up.forEach((item: any) => {
          item['long_term'] = typeof item['long_term'] === 'string' ? JSON.parse(item['long_term']) : (item['long_term'] || []);
          item['short_term'] = typeof item['short_term'] === 'string' ? JSON.parse(item['short_term']) : (item['short_term'] || []);
        });
      }
      return { data, ...res };
    }
  });
}

