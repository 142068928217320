import { Button, Container, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Trans } from "react-i18next";

const ErrorPage = () => {
  return (
    <Container id="AuthLayout" maxW="100%" h="100vh" display="flex" flexDir="column" px={10}>
      <VStack w="fit-content" h="fit-content" m="auto" spacing="4">
        <Text color="red.500" fontSize="7xl" fontWeight="extrabold" lineHeight="1">
          500
        </Text>
        <Text fontWeight="bold" fontSize="2xl">
          <Trans>COMMON.UNEXPECTED_ERROR_OCCURRED</Trans>
        </Text>
        <HStack spacing="2">
          <Button as="a" href="/" colorScheme="blue">
            <Trans>COMMON.GO_TO_HOME_PAGE</Trans>
          </Button>
        </HStack>
      </VStack>
    </Container>
  );
};

export default ErrorPage;
